/* global config, angular, moment, PDFJS, Promise, parseInt, FormData, gData */

'use strict';
angular.module('managerApp').controller('ProcedureController', (
        $scope, $rootScope, $route, $window, $routeParams, $location, $timeout,
        $cookies, $mdDialog, toast, procedureService, procedureTools, documents,
        Auth, smsCounter, clients, assistancePopup, ants, procedureParams) => {

//------------------------------------------------------------------------------

    $scope.config = config;
    $scope.PROCEDURE_PARAMS = procedureParams;
    $scope.defaultMessages = procedureTools.messages;
    $scope.documentTypes = procedureTools.getDocumentTypes();
    $scope.currentUser = $rootScope.currentUser;

//------------------------------------------------------------------------------

    const path = $location.path();
    const PROCEDURES_DIR = config.app.proceduresDir;
    const TEMPLATES_DIR = config.app.templatesDir;
    const DEFAULT_ERROR_MESSAGE = config.app.defaultErrorMessage;
    const itemsPerPageCookie = config.app.cookies.itemsPerPage;
    const pendingOnlyProceduresCookie = config.app.cookies.pendingOnlyProcedures;

//------------------------------------------------------------------------------

    $scope.hasProcedureEditPermission = Auth.hasPermission($scope.PROCEDURE_PARAMS.serviceName, 'PUT');
    $scope.hasTMSPermission = Auth.hasPermission('TMS', 'PUT');
    $scope.hasANTSProcedures = $scope.PROCEDURE_PARAMS.hasANTS;

    if ($scope.hasANTSProcedures) {
        $scope.hasANTSGetPermission = Auth.hasPermission('ANTS', 'GET');
        $scope.hasANTSFullPermission = Auth.hasPermission('ANTS', 'PUT');
    }
    $scope.hasTaxes = $scope.PROCEDURE_PARAMS.hasTaxes;
    $scope.isCIM = $scope.PROCEDURE_PARAMS.isCIM || false;
    $scope.isCIWW = $scope.PROCEDURE_PARAMS.isCIWW || false;
    $scope.isCIVI = $scope.PROCEDURE_PARAMS.isCIVI || false;
    $scope.isCAD = $scope.PROCEDURE_PARAMS.isCAD || false;
    $scope.isDUP = $scope.PROCEDURE_PARAMS.isDUP || false;
    $scope.isDC = $scope.PROCEDURE_PARAMS.isDC || false;
    $scope.isDA = $scope.PROCEDURE_PARAMS.isDA || false;
    $scope.isVEL = $scope.PROCEDURE_PARAMS.isVEL || false;
    $scope.needBirthInfos = $scope.isCIM || $scope.isCIWW;
    $scope.needHostingInfo = $scope.isCIM || $scope.isCIWW || $scope.isCAD || $scope.isDUP;
    $scope.ownerOnlyProcedure = $scope.isCAD || $scope.isDUP;

    $scope.isForeignVehicle = $scope.isCIWW || $scope.isCIVI;

    function setProcedureEditPermissions() {
        let proceduresTypes = procedureTools.getAllProcedureTypes();
        $scope.userProcedureEditPermissions = {};
        for (let i in $scope.PROCEDURE_PARAMS.procedures) {
            let procedureType = $scope.PROCEDURE_PARAMS.procedures[i];
            $scope.userProcedureEditPermissions[procedureType.type] = Auth.hasPermission(procedureType.serviceName, 'PUT');
        }
    }

    $scope.getTabber = function ($mdTabsCtrl) {
        $scope.$mdTabsCtrl = $mdTabsCtrl;
    };

//------------------------------------------------------------------------------

    $scope._type = $scope.PROCEDURE_PARAMS.type;

    if ($scope._type === 'never-handled') {
        $scope.isNeverHandledProcedures = true;
    }

    $scope.currentTab = $routeParams.tab || 0;
    $scope.docsCurrentTab = 0;

    if ($scope.currentTab > 4) {
        $scope.currentTab = 0;
    }

    $scope.procedureId = $routeParams.id;
    $scope.isItem = typeof $scope.procedureId !== 'undefined';

    // for item
    $scope.procedure = null;
    $scope.procedureType = null;
    $scope.antsProcedure = null;
    $scope.client = null;
    $scope.seller = null;
    $scope.buyer = null;
    $scope.owner = null;
    $scope.defaultPerson = null;
    $scope.coBuyers = [];
    $scope.firstCoBuyer = null;
    $scope.vehicle = null;
    $scope.terminal = null;
    $scope.isTerminalProcedure = false;
    $scope.item = null;
    $scope.editedSeller = null;
    $scope.editSeller = false;
    $scope.editedOwner = null;
    $scope.editOwner = false;
    $scope.editedBuyer = null;
    $scope.editBuyer = false;
    $scope.origin = null;
    $scope.proceduredocument = null;

    $scope.showDefaultDataWarning = false;
    $scope.defaultDataWarningMsg = null;
    $scope.hasTMSErrors = false;

    // for list
    $scope.proceduresCount = null;
    $scope.procedures = [];
    $scope.isANTS = false;
    $scope.currentProceduresTab = 0;
    $scope.antsProcedureMotifs = [];

    $scope.isOldProcedure = false;

    var lastProceduresReqTime = null;

//------------------------------------------------------ search & pagination ---

    let searchItemsPerPage = $cookies.get(itemsPerPageCookie) || 25;
    let maxDate = new Date();
    maxDate.setHours(23, 59, 59);
    $scope.pagination = {
        currentPage: 1,
        maxSize: 5,
        itemsPerPage: searchItemsPerPage,
        totalItems: 0,
        maxDate: maxDate,
        filters: {},
        pendingOnlyProcedures: $cookies.get(pendingOnlyProceduresCookie) === 'true',
        pageChanged: (loadData) => {
            $cookies.put(pendingOnlyProceduresCookie, $scope.pagination.pendingOnlyProcedures);
            $cookies.put(itemsPerPageCookie, $scope.pagination.itemsPerPage);
            if (loadData === true) {
                getProcedures();
            }
        }
    };

    $rootScope.$on('get-procedures', function (e, type) {
        if (type === $scope._type) {
            $scope.pagination.pendingOnlyProcedures = e.targetScope.search.pendingOnlyProcedures;
            $scope.pagination.filters = e.targetScope.search.filters;
            getProcedures();
        }
    });

    $rootScope.$on('create-payment', function (e, pymentParams) {
        $scope.showPaymentPopup(pymentParams.procedureId, pymentParams.amount, pymentParams.motif);
    });

    $rootScope.$on('create-partiel-taxes-refund', function (e, refundParams) {
        $scope.addRefund(refundParams.procedureId, refundParams.amount, refundParams.type);
    });

    setTimeout(() => {
        $rootScope.$broadcast('get-ants-available', {
            hasANTSProcedures: $scope.hasANTSProcedures
        });
    }, 0);

//------------------------------------------------------------------------------

    $scope.gotoTab = (tabIndex = - 1, scrollTo = null) => {
        $rootScope.gotoTab($scope, tabIndex, scrollTo);
    };

    $scope.openMenu = function ($mdMenu, e) {
        $mdMenu.open(e);
    };

    $scope.onTabSelect = (tabIndex = - 1) => {
        $location.search('tab', tabIndex < 0 ? null : tabIndex);
    };

    $scope.onProceduresTabSelect = (tabIndex = - 1) => {
        $scope.isANTS = tabIndex === 1;
        getProcedures();
    };

    function openExternalLink(url = '', target = '_blank') {
        $window.open(url, target);
    }

    $scope.openRemoteSession = (remoteId = '') => {
        if (remoteId.length > 2) {
            openExternalLink('anydesk://' + remoteId);
        }//
    };

    $scope.showSearchPanel = () => {
        $rootScope.$broadcast('show-search-panel');
    };

//--------------------------------------------------------------- data edits ---

    $scope.editSaleDateTime = true;
    function setDateTimePicker() {
        let now = new Date(), minDate = new Date();
        $scope.dateTimePicker = null;
        now.setMilliseconds(0);
        now.setSeconds(0);
        minDate.setYear(now.getFullYear() - 50);
        $scope.dateTimePicker = {
            saleDateTime: now,
            minDate: moment(minDate).format('YYYY-MM-DDTHH:mm'),
            maxDate: moment(now).format('YYYY-MM-DDTHH:mm'),
            save: function () {
                if (verifyProcedureHandle()) {
                    let saleDateTime = moment($scope.dateTimePicker.saleDateTime);
                    let data = {
                        saleDate: saleDateTime.format('DD/MM/YYYY'),
                        saleTime: saleDateTime.format('HH:mm')
                    };
                    procedureService.updateItem($scope.procedureId, $scope._type, $scope.item._id, data).then(() => {
                        toast.show('Les données ont été bien mises à jour.', 'success');
                        $scope.item.saleDate = data.saleDate;
                        $scope.item.saleTime = data.saleTime;
                        $scope.setSaleDateTimeEdit(false);
                        $timeout(() => {
                            $scope.$apply();
                        });
                    }).catch((err) => {
                        toast.show(err && err.message ? err.message : DEFAULT_ERROR_MESSAGE);
                    });
                }
            }
        };
    }
    $scope.setSaleDateTimeEdit = (value) => {
        if (value) {
            setDateTimePicker();
        }
        $scope.editSaleDateTime = value;
    };
    $scope.eventDatePicker = null;
    function setEventDatePicker(eventDate = null) {
        let now = new Date();
        now.setHours(0);
        now.setMinutes(0);
        now.setSeconds(0);
        now.setMilliseconds(0);
        let minDate = new Date(0, 0, 0, 0, 0, 0, 0);
        minDate.setYear(now.getFullYear() - 3);
        if (eventDate === null) {
            eventDate = now;
        }
        $scope.eventDatePicker = {
            eventDate: moment(eventDate, 'DD/MM/YYYY').toDate(),
            minDate: moment(minDate).format('YYYY-MM-DD'),
            maxDate: moment(now).format('YYYY-MM-DD'),
            save: function () {
                if (verifyProcedureHandle(true)) {
                    let _eventDate = moment(this.eventDate);
                    if (_eventDate.isAfter(moment(now)) || _eventDate.isBefore(moment(minDate))) {
                        return toast.show('La date semble invalide.', 'error');
                    }
                    let data = {eventDate: _eventDate.format('DD/MM/YYYY')};
                    procedureService.updateItem($scope.procedureId, $scope._type, $scope.item._id, data).then(() => {
                        toast.show('Les données ont été bien mises à jour.', 'success');
                        $scope.item.eventDate = data.eventDate;
                        $timeout(() => {
                            $scope.$apply();
                        });
                    }).catch((err) => {
                        toast.show(err && err.message ? err.message : DEFAULT_ERROR_MESSAGE);
                    });
                }
            }
        };
    }

    $scope.save_CIWWMotif = function () {
        if (verifyProcedureHandle(true)) {
            let data = {motif: $scope.item.motif};
            procedureService.updateItem($scope.procedureId, $scope._type, $scope.item._id, {
                motif: $scope.item.motif
            }).then(() => {
                toast.show('Les données ont été bien mises à jour.', 'success');
                $timeout(() => {
                    $scope.$apply();
                });
            }).catch((err) => {
                toast.show(err && err.message ? err.message : DEFAULT_ERROR_MESSAGE);
            });
        }
    };

    $scope.cancelSellerEdit = () => {
        $scope.editSeller = false;
        $scope.editedSeller = {};
        $timeout(() => {
            $scope.$apply();
        });
    };
    $scope.saveEditedSeller = () => {
        if ($scope.editSeller) {
            procedureService.updateItem($scope.procedure._id, 'person', $scope.seller._id, $scope.editedSeller).then(() => {
                toast.show('Les données ont été bien mises à jour.', 'success');
                $route.reload();
            }).catch((err) => {
                toast.show(err && err.message ? err.message : DEFAULT_ERROR_MESSAGE);
            });
        }
    };
    $scope.cancelBuyerEdit = () => {
        $scope.editBuyer = false;
        $scope.editedBuyer = {};
        $timeout(() => {
            $scope.$apply();
        });
    };
    $scope.saveEditedBuyer = () => {
        if ($scope.editBuyer) {
            procedureService.updateItem($scope.procedure._id, 'person', $scope.buyer._id, $scope.editedBuyer).then(() => {
                toast.show('Les données ont été bien mises à jour.', 'success');
                $route.reload();
            }).catch((err) => {
                toast.show(err && err.message ? err.message : DEFAULT_ERROR_MESSAGE);
            });
        }
    };

    $scope.cancelOwnerEdit = () => {
        $scope.editOwner = false;
        $scope.editedOwner = {};
        $timeout(() => {
            $scope.$apply();
        });
    };
    $scope.saveEditedOwner = () => {
        if ($scope.editOwner) {
            procedureService.updateItem($scope.procedure._id, 'person', $scope.owner._id, $scope.editedOwner).then(() => {
                toast.show('Les données ont été bien mises à jour.', 'success');
                $route.reload();
            }).catch((err) => {
                toast.show(err && err.message ? err.message : DEFAULT_ERROR_MESSAGE);
            });
        }
    };

    /*$scope.saveAddressWayType = (addressId, data) => {
     if (verifyProcedureHandle(true)) {
     let data = {motif: $scope.item.motif};
     procedureService.updateItem($scope.procedureId, $scope._type, $scope.item._id, {
     motif: $scope.item.motif
     }).then(() => {
     toast.show('Les données ont été bien mises à jour.', 'success');
     $timeout(() => {
     $scope.$apply();
     });
     }).catch((err) => {
     toast.show(err && err.message ? err.message : DEFAULT_ERROR_MESSAGE);
     });
     }
     };*/

    $scope.edit = ($event, fieldSize = 16, inputType = 'text', placeholder = '', maxLength = 50) => {
        if (verifyProcedureHandle()) {
            let _this = $event.currentTarget;
            let parent = $(_this).parent();
            let item = $(getTargetItem($event));
            let type = $(item).data('type');
            let value = $(item).data('value');
            let field = $(item).data('field');
            let id = $(item).data('data-id');
            let personSubType = $(item).data('sub-type');
            $scope.editSeller = false;
            if (field === 'personType') {
                let _personType = value === 'personne-physique' ? 'personne-morale' : 'personne-physique';
                let _person = {
                    type: personSubType,
                    personType: _personType,
                    isPro: value === 'personne-morale'
                };
                if (personSubType === 'seller') {
                    $scope.editSeller = true;
                    $scope.editedSeller = _person;
                } else if (personSubType === 'buyer') {
                    $scope.editBuyer = true;
                    $scope.editedBuyer = _person;
                } else if (personSubType === 'owner') {
                    $scope.editOwner = true;
                    $scope.editedOwner = _person;
                }
                return;
            }
            if (value === true) {
                value = 'OUI';
            } else if (value === false) {
                value = 'NON';
            } else if (value === 'personne-physique') {
                value = 'Particulier';
            } else if (value === 'personne-morale') {
                value = 'Société';
            }
            let inputFields = {
                type: inputType,
                field: type + '.' + field,
                size: fieldSize,
                value: value,
                placeholder: placeholder,
                maxlength: maxLength,
                class: 'edit-input',
                id: id + '_' + type + '_' + field
            };
            if (field === 'saleDate,saleTime') {
                let saleDate = $(item).data('data-saledate');
                let saleTime = $(item).data('data-saletime');
                value = moment(saleDate + ' ' + saleTime, 'DD/MM/YYYY HH:mm', true).format('YYYY-MM-DDTHH:mm');
                let now = new Date(), minDate = new Date();
                now.setMilliseconds(0);
                now.setSeconds(0);
                minDate.setYear(now.getFullYear() - 50);
                inputFields.value = value;
                inputFields.min = moment(minDate).format('YYYY-MM-DDTHH:mm');
                inputFields.max = moment(now).format('YYYY-MM-DDTHH:mm');
            }
            $(item).html('');
            let inputHTML = '<input ';
            for (let f in inputFields) {
                inputHTML += f + '="' + inputFields[f] + '" ';
            }
            inputHTML += '>';
            $(item).append(inputHTML);
            setTimeout(() => {
                let input = document.getElementById(inputFields.id);
                let end = input.value.length;
                input.setSelectionRange(end, end);
                input.focus();
            }, 50);
            $(_this).addClass('hidden');
            $(parent).find($('.btn-validate-edit')).removeClass('hidden');
            $(parent).find($('.btn-cancel-edit')).removeClass('hidden');
            if ($(item).hasClass('badge')) {
                $(item).removeClass('badge');
                $(item).addClass('badge-off');
            }//
        }//
    };
    $scope.cancelEdit = ($event) => {
        let _this = $event.currentTarget;
        let parent = $(_this).parent();
        let item = $(getTargetItem($event));
        let value = $(item).data('value');

        if (typeof value === 'boolean') {
            value = value === true ? 'Oui' : 'Non';
        }

        $(item).html(value);
        $(_this).addClass('hidden');
        $(parent).find($('.btn-edit')).removeClass('hidden');
        $(parent).find($('.btn-validate-edit')).addClass('hidden');
        if ($(item).hasClass('badge-off')) {
            $(item).removeClass('badge-off');
            $(item).addClass('badge');
        }
    };
    $scope.validateEdit = ($event, origItem) => {
        let _this = $event.currentTarget;
        let parent = $(_this).parent();
        let item = $(getTargetItem($event));

        let type = $(item).data('type'),
                value = $(item).data('value') + '',
                field = $(item).data('field'),
                itemId = $(item).data('data-id'),
                itemType = $(item).data('data-type'),
                itemPersonTitle = $(item).data('data-title'),
                itemPersonType = $(item).data('data-person-type');

        let newValue = ($(item).find('input').val() || '');
        if (field !== 'D2_Version') {
            newValue = newValue.toUpperCase();
        }
        let company = null, firstname = null, lastname = null;

        if (newValue === 'PARTICULIER') {
            newValue = 'personne-physique';
        } else if (newValue === 'SOCIÉTÉ') {
            newValue = 'personne-morale';
            company = prompt('Veuillez taper le nom de la société', '');
        } else if (newValue === 'OUI' || newValue === 'TRUE') {
            newValue = true;
        } else if (newValue === 'NON' || newValue === 'FALSE') {
            newValue = false;
        }
        let data = type === 'person' ? {
            type: itemType,
            personType: itemPersonType
        } : {};
        if (company !== null && company.length > 1) {
            data.company = company;
            console.error('TRY TO SEND SIREN & COMPANY IN THE SAME REQUEST TO AVOID API ERROR MESSAGE !!!');
            //data.siren = ''
        }
        if (['certificat-immatriculation', 'declaration-cession',
            'declaration-achat'].indexOf(type) > -1) {
            if (field === 'saleDate,saleTime') {
                let saleDateTime = moment(newValue);
                data.saleDate = saleDateTime.format('DD/MM/YYYY');
                data.saleTime = saleDateTime.format('HH:mm');
            }
        }
        if (field === 'marriedname') {
            data['title'] = itemPersonTitle;
        }
        if (newValue !== value.toUpperCase()) {
            /*if (['TRUE', 'FALSE', 'OUI', 'NON'].indexOf(newValue) > -1) {
             newValue = newValue === 'TRUE' || newValue === 'OUI';
             }*/
            if (field !== 'saleDate,saleTime') {
                //$(item).data('value', newValue);
                data[field] = newValue;
            }
            procedureService.updateItem($scope.procedure._id, type, itemId, data).then((data) => {
                let msg = 'Les données ont été bien mises à jour.';
                if (data && data.message) {
                    msg = data.message;
                }
                toast.show(msg, 'success');
                if (typeof newValue === 'boolean') {
                    $(item).html(newValue ? 'Oui' : 'Non');
                } else {
                    if (newValue === 'personne-physique') {
                        $(item).html('Particulier');
                    } else if (newValue === 'personne-morale') {
                        $(item).html('Société');
                    } else {
                        $(item).html(newValue);
                    }
                }
                $(item).data('value', newValue);
                origItem[field] = newValue;
                $(_this).addClass('hidden');
                $(parent).find($('.btn-edit')).removeClass('hidden');
                $(parent).find($('.btn-cancel-edit')).addClass('hidden');
                if ($(item).hasClass('badge-off')) {
                    $(item).removeClass('badge-off');
                    $(item).addClass('badge');
                }
            }).catch((err) => {
                toast.show(err && err.message ? err.message : DEFAULT_ERROR_MESSAGE);
            });
        }
    };

    $scope.createDCSellerAddress = function (person) {
        if (verifyProcedureHandle()) {
            if (person && typeof (person.Address || {})._id === 'string') {
                return;
            }
            let data = {
                personId: person._id,
                type: person.type,
                personType: person.personType,
                Address: person.Address
            };
            procedureService.updateItem($scope.procedure._id, 'person', 'new-address', data).then((data) => {
                let msg = 'Les données ont été bien mises à jour.';
                if (data && data.message) {
                    msg = data.message;
                }
                toast.show(msg, 'success');
            }).catch((err) => {
                toast.show(err && err.message ? err.message : DEFAULT_ERROR_MESSAGE);
            });
        }
    };

    function getTargetItem($event) {
        let elem = $event.currentTarget;
        return $(elem).parent().parent().find('.item-field');
    }

//------------------------------------------------------------------------------
//----------------------------------------------------------------- handling ---

    $scope.handleUnhandle = (procedureId, unhandle = false, isFromList = false) => {
        if (unhandle === false) {
            doHandleUnhandle(procedureId, unhandle, isFromList);
        } else {
            assistancePopup.show($rootScope.currentUser._id, procedureId).then(() => {
                doHandleUnhandle(procedureId, unhandle, isFromList);
            }).catch((err) => {
                if (err !== null) {
                    toast.show(err && err.message ? err.message : DEFAULT_ERROR_MESSAGE);
                }
                doHandleUnhandle(procedureId, unhandle, isFromList);
            });
        }//
    };
    function doHandleUnhandle(procedureId, unhandle, isFromList) {
        procedureService.handleUnhandle(procedureId, unhandle, isFromList).then((res) => {
            toast.show(unhandle ? 'La prise en charge de cette démarche a bien été arrêtée.' :
                    'La démarche a bien été prise en charge. ', 'success', true, 10000);
            $location.search({});
            $route.reload();
        }).catch((err) => {
            toast.show(err && err.message ? err.message : DEFAULT_ERROR_MESSAGE, 'error', true, 10000);
        });
    }
    function verifyProcedureHandle(showAlert = true, verifyPermission = null) {
        if ($scope.procedure.isHandledByUser) {
            if (verifyPermission !== null && typeof verifyPermission === 'boolean') {
                if (verifyPermission === true) {
                    return true;
                } else {
                    if (showAlert) {
                        toast.show("Vous n'avez apparemment pas la permission pour pouvoir exécuter cette action.", 'error', true);
                        return false;
                    }
                }
            }
            return true;
        } else {
            if (showAlert) {
                toast.show('Vous devez prendre en charge cette démarche' +
                        ' pour pouvoir exécuter cette action.', 'error', true);
            }
            return false;
        }//
    }

//----------------------------------------------------------------- cobuyers ---

    $scope.showAddNewCobuyerPopup = () => {
        if (verifyProcedureHandle()) {
            $mdDialog.show({
                templateUrl: TEMPLATES_DIR + 'new.cobuyer.html',
                parent: angular.element(document.body),
                fullscreen: true,
                locals: {
                    procedureId: $scope.procedureId
                },
                controller: ($scope, toast, procedureId) => {
                    $scope.newCoBuyer = {};
                    $scope.closeDialog = (confirm = false) => {
                        if (confirm) {
                            $scope.procedureId = procedureId;
                            $scope.newCoBuyer.type = $scope.newCoBuyer.personType;
                            if (typeof $scope.newCoBuyer.firstCoBuyer === 'undefined') {
                                $scope.newCoBuyer.firstCoBuyer = false;
                            }
                            procedureService.updateItem($scope.procedureId, 'person', 'new-cobuyer', $scope.newCoBuyer).then(() => {
                                toast.show('Le coacquéreur a bien été ajouté à cette démarche.', 'success');
                                $route.reload();
                                $mdDialog.hide();
                            }).catch((err) => {
                                toast.show(err && err.message ? err.message : DEFAULT_ERROR_MESSAGE, 'error');
                            });
                        }//
                    };
                }
            }).then((answer) => {
                console.log('answer : ' + answer);
            }, () => {
                console.log('You cancelled the dialog.');
            });
        }
    };
    $scope.destroyCoBuyer = (item, event) => {
        let procedureId = $scope.procedure._id;
        if (verifyProcedureHandle()) {
            let confirm = $mdDialog.confirm()
                    .title('Supprimer un coacquéreur')
                    .textContent('Voulez-vous vraiment supprimer ce coacquéreur ?')
                    .targetEvent(event)
                    .ok('Oui')
                    .cancel('Non')
                    .theme('confirm')
                    .multiple(true);
            $mdDialog.show(confirm, {multiple: true}).then(() => {
                let data = {
                    type: item.type,
                    action: 'delete',
                    personType: item.personType
                };
                procedureService.updateItem(procedureId, 'person', item._id, data).then(() => {
                    toast.show('Le coacquéreur a bien été supprimé.', 'success');
                    $route.reload();
                }).catch((err) => {
                    toast.show(err && err.message ? err.message : DEFAULT_ERROR_MESSAGE, 'error');
                });
            }, () => {
                // cancelled
            });
        }
    };

//---------------------------------------------------------------- documents ---    

    $scope.generateDocumentsToSign = () => {
        if (verifyProcedureHandle()) {
            procedureService.generateDocumentsToSign($scope.procedure._id, $scope.procedure.type, $scope.client._id).then((res) => {
                let msg = 'Les documents à signer ont été bien générés.';
                let timeout = 3000;
                if (res.message) {
                    timeout = 5000;
                    msg = res.message;
                } else {
                    $location.search('tab', 2);
                    $route.reload();
                }
                toast.show(msg, 'success', true, timeout);
            }).catch((err) => {
                toast.show(err && err.message ? err.message : DEFAULT_ERROR_MESSAGE, 'error', true, 30000);
            });
        }
    };
    $scope.addDocument = () => {
        if (verifyProcedureHandle()) {
            $mdDialog.show({
                parent: angular.element(document.body),
                templateUrl: TEMPLATES_DIR + 'add.document.html',
                locals: {
                    procedureId: $scope.procedure._id,
                    documentTypes: $scope.documentTypes
                },
                controller: ($scope, $mdDialog, toast, procedureTools, procedureService, procedureId, documentTypes) => {

                    $scope.documentTypes = documentTypes;
                    $scope.documentSubTypes = [];
                    $scope.selectedDocumentType = null;
                    $scope.selectedDocumentSubType = null;
                    $scope.valid = false;

                    for (let i = 0; i < $scope.documentTypes.length; i++) {
                        let d = $scope.documentTypes[i];
                        if (d.name.indexOf('piece-identite-heritier') > -1) {
                            d.label += ' ' + d.name.substr(-2, 2);
                        }
                    }

                    $scope.onDocumentTypeSelect = ($event, subTypeSelect) => {
                        if (!subTypeSelect) {
                            $scope.documentSubTypes = null;
                            $scope.selectedDocumentSubType = null;
                            let selectedDocumentType = JSON.parse($scope.selectedDocumentType);
                            if (selectedDocumentType.hasSubTypes === true) {
                                $scope.valid = false;
                                $scope.documentSubTypes = procedureTools.getDocumentTypes(selectedDocumentType.name);
                            } else {
                                $scope.valid = true;
                            }
                        } else {
                            if ($scope.selectedDocumentSubType !== null) {
                                $scope.valid = true;
                            } else {
                                $scope.valid = false;
                            }
                        }
                        $timeout(() => {
                            $scope.$apply();
                        });
                    };

                    $scope.closeDialog = (confirm = false) => {
                        if (confirm) {
                            let documentType = JSON.parse($scope.selectedDocumentType);
                            let documentSubType = $scope.selectedDocumentSubType !== null ? JSON.parse($scope.selectedDocumentSubType) : null;
                            procedureService.addDocument(procedureId, {
                                type: documentType.name,
                                subType: documentSubType && documentSubType.name !== documentType.name ? documentSubType.name : null,
                                field: documentSubType ? documentSubType.name : documentType
                            }).then((res) => {
                                let msg = 'Le document à bien été ajouté à la liste des documents de la démarche.';
                                let timeout = 3000;
                                if (res.message) {
                                    timeout = 5000;
                                    msg = res.message;
                                } else {
                                    $mdDialog.hide();
                                    $location.search('tab', 2);
                                    $route.reload();
                                }
                                toast.show(msg, 'success', true, timeout);
                            }).catch((err) => {
                                toast.show(err && err.message ? err.message : DEFAULT_ERROR_MESSAGE, 'error', true, 30000);
                            });
                        } else {
                            $mdDialog.hide();
                        }//
                    };
                }
            });
        }
    };
    $scope.checkDocument = (document) => {
        if (!document) {
            return;
        }
        if (verifyProcedureHandle()) {
            if (document.concordance && (document.position > document.concordance.document.position)) {
                let msg = 'Vous ne pouvez pas valider ce document car il ' +
                        'est nécessaire de vérifier sa concordance par ' +
                        'rapport ' + document.concordance.documentLabel + ' ',
                        l = msg.length;
                if (document.concordance.document === null) {
                    msg += 'qui n\'a pas encore été téléversé.';
                } else if (document.concordance.document.status !== 'document-accepted') {
                    msg += 'qui n\'a pas encore été validé.';
                }
                if (msg.length > l) {
                    toast.show(msg, 'error', true);
                    return;
                }
            }
            if (document.type === 'certificat-cession') {
                if ($scope.item.saleDate === null || $scope.item.saleTime === null) {
                    toast.show('Il faut préciser la date et l\'heure de cession ' +
                            'pour la démarche.', 'error', true);
                }
            }
            document.validation = procedureTools.getDocValidationQuestions(document);
            procedureTools.openDocumentsViewer(document, $scope);
        }
    };
    $scope.resetDocument = (document, event) => {
        if (verifyProcedureHandle()) {
            let confirm = $mdDialog.confirm()
                    .title('Réinitialiser un document')
                    .textContent('Voulez-vous vraiment réinitialiser ce document ?')
                    .targetEvent(event)
                    .ok('Oui')
                    .cancel('Non')
                    .theme('confirm');

            $mdDialog.show(confirm).then(() => {
                documents.reset(document).then(() => {
                    toast.show('Le document a bien été réinitialisé.', 'success');
                    $location.search('tab', 2);
                    $route.reload();
                }).catch((err) => {
                    toast.show(err && err.message ? err.message : DEFAULT_ERROR_MESSAGE);
                });
            }, () => {
                // cancelled
            });
        }
    };
    $scope.uploadDocument = (document, showAdditionnalDocs = false) => {
        if (verifyProcedureHandle()) {
            $mdDialog.show({
                templateUrl: TEMPLATES_DIR + 'upload.document.html',
                clickOutsideToClose: false,
                locals: {
                    document: document,
                    procedure: $scope.procedure,
                    showAdditionnalDocs: showAdditionnalDocs
                },
                controller: ($scope, $mdDialog, procedureService, procedure, document, showAdditionnalDocs) => {

                    $scope.docToUpload = document;
                    $scope.showAdditionnalDocs = showAdditionnalDocs;
                    $scope.procedure = procedure;
                    $scope.document = null;

                    if ($scope.showAdditionnalDocs === true) {
                        $scope.docToUpload = {};
                    }
                    $scope.onDocumentFieldSelect = (field) => {
                        $scope.docToUpload.field = field;
                        $scope.docToUpload.type = field;
                        if (field === 'certificat-situation-administrative-detaille') {
                            $scope.docToUpload.type = 'certificat-situation-administrative';
                        }
                    };
                    $scope.onFileSelect = (files, target) => {
                        let file = files[0];
                        if (!file) {
                            return;
                        }
                        $scope.$apply(function () {
                            $scope.document = files[0];
                        });
                    };
                    $scope.closeDialog = (validated = false) => {
                        if (!validated) {
                            $mdDialog.cancel();
                        } else {
                            let formData = new FormData();
                            formData.append($scope.docToUpload.field, $scope.document);
                            procedureService.uploadDocument($scope.procedure, formData, $scope.docToUpload.type).then((res) => {
                                $mdDialog.cancel();
                                toast.show('Le document a bien été téléversé.', 'success');
                                $location.search('tab', 2);
                                $route.reload();
                            }).catch((err) => {
                                console.error('upload document error :');
                                console.log(err);
                                toast.show(err, 'error', true);
                            });
                        }//
                    };
                }
            });
        }//
    };
    $scope.destroyDocument = (documentId) => {
        if (verifyProcedureHandle()) {
            $mdDialog.show({
                parent: angular.element(documentId.body),
                templateUrl: TEMPLATES_DIR + 'delete.document.html',
                controller: ($scope, $mdDialog, toast) => {
                    $scope.closeDialog = (confirm = false) => {
                        $mdDialog.hide();
                        if (confirm) {
                            documents.delete(documentId).then(() => {
                                toast.show('Le document a bien été supprimé.', 'success');
                                $route.reload();
                            }).catch((err) => {
                                toast.show(err && err.message ? err.message : DEFAULT_ERROR_MESSAGE, 'error');
                            });
                        }//
                    };
                }
            });
        }
    };

    $scope.removeExtraDocument = (extraDocument) => {
        if (verifyProcedureHandle()) {
            $mdDialog.show({
                parent: angular.element(document.body),
                templateUrl: TEMPLATES_DIR + 'remove.extra.document.html',
                locals: {
                    procedureId: $scope.procedureId,
                    document: extraDocument
                },
                controller: ($scope, $mdDialog, toast, document, procedureId) => {
                    $scope.document = document;
                    $scope.closeDialog = (confirm = false) => {
                        $mdDialog.hide();
                        if (confirm) {
                            procedureService.removeExtraDocument(procedureId, {
                                type: $scope.document.type,
                                field: $scope.document.field
                            }).then(() => {
                                toast.show('Le document a bien été supprimé de la liste de documents de la démarche.', 'success');
                                $route.reload();
                            }).catch((err) => {
                                toast.show(err && err.message ? err.message : DEFAULT_ERROR_MESSAGE, 'error');
                            });
                        }//
                    };
                }
            });
        }
    };

    $scope.showCompareDocumentsPopup = () => {
        procedureTools.openCompareDocumentsViewer($scope);
    };

    $scope.openNewDocumentViewer = function () {
        if (verifyProcedureHandle()) {
            $mdDialog.show({
                templateUrl: TEMPLATES_DIR + 'new.document.viewer.html',
                parent: angular.element(document.body),
                fullscreen: true,
                multiple: true,
                locals: {
                    procedure: $scope.procedure,
                    documents: $scope.procedure.Documents,
                    removeCoBuyer: $scope.destroyCoBuyer,
                    data: {
                        procedure: $scope.item,
                        procedureType: $scope._type,
                        procedureId: $scope.procedureId,
                        client: $scope.client,
                        vehicle: $scope.vehicle,
                        seller: $scope.seller,
                        buyer: $scope.buyer,
                        owner: $scope.owner,
                        coBuyers: $scope.coBuyers,
                        ownerOldAddress: $scope.oldAddress,
                        ownerNewAddress: $scope.owner ? $scope.owner.Address : {}
                    }
                },
                controller: ($scope, procedure, documents, removeCoBuyer, data) => {

                    $scope.Procedure = procedure;
                    $scope.documentsViewerOnlyMode = $scope.Procedure.allDocumentsUploaded !== true;

                    $scope.procedure = data.procedure;

                    $scope.procedureId = data.procedureId;
                    $scope.procedureType = data.procedureType;
                    $scope.client = data.client;
                    $scope.seller = data.seller;
                    $scope.buyer = data.buyer;
                    $scope.owner = data.owner;
                    $scope.vehicle = data.vehicle;
                    $scope.coBuyers = data.coBuyers;

                    $scope.ownerOldAddress = data.ownerOldAddress;
                    $scope.ownerNewAddress = data.ownerNewAddress;

                    $scope.removeCoBuyer = removeCoBuyer;

                    $scope.showLeftSide = false;
                    $scope.currentDocumentTab = 0;

                    $scope.rawDocuments = documents;
                    $scope.documents = [];

                    $scope.rejectedDocuments = [];

                    const imageViewerParams = {
                        inline: true,
                        backdrop: false,
                        button: false,
                        navbar: false,
                        title: (image) => image.alt,
                        keyboard: false,
                        loading: true,
                        toolbar: {
                            zoomIn: {show: true},
                            zoomOut: {show: true},
                            oneToOne: {show: true},
                            reset: {show: true},
                            rotateLeft: {show: true},
                            rotateRight: {show: true},
                            flipHorizontal: {show: true},
                            flipVertical: {show: true},
                            play: {show: false}
                        },
                        viewed() {
                        }
                    };

                    for (let i = 0; i < $scope.rawDocuments.length; i++) {
                        let index = $scope.documents.length;
                        let doc = $scope.rawDocuments[i];
                        if (doc.isToUpload) {
                            if ($scope.Procedure.isCIM && $scope.Procedure.allDocumentsUploaded === true && doc.isCIM) {
                                continue;
                            } else {
                                doc.isDocument = true;
                                let items = {
                                    label: doc.label,
                                    document01: null,
                                    document02: null
                                };
                                let concordance = doc.concordance;
                                if (concordance !== null) {
                                    concordance.document.isConcordance = true;
                                    if (concordance.document.isLeft === true) {
                                        items.document01 = Object.assign({}, concordance.document);
                                        items.document02 = Object.assign({}, doc);
                                        items.document01.index = 'doc_' + index + '_01';
                                        items.document02.index = 'doc_' + index + '_02';
                                    } else {
                                        items.document02 = Object.assign({}, concordance.document);
                                        items.document02.index = 'doc_' + index + '_02';
                                    }
                                } else {
                                    items.document01 = Object.assign({}, doc);
                                    items.document01.index = 'doc_' + index + '_01';
                                }
                                $scope.documents.push(items);
                            }
                        }
                    }

                    setTimeout(() => {
                        for (let i = 0; i < $scope.documents.length; i++) {
                            let row = $scope.documents[i];
                            row.viewers = [];
                            if (row.document01) {
                                if (row.document01.fileType === 'image') {
                                    let img = _createImageElement(row.document01.index + '_view', row.document01.url, row.document01.index);
                                    img.alt = row.document01.label;
                                    if (row.document01.isType === false) {
                                        img.alt = row.document01.typeLabel + ' : ' + row.document01.label;
                                    }
                                    row.viewers[0] = new Viewer(img, imageViewerParams);
                                } else {
                                    _createObjectElement(row.document01.index + '_view', row.document01.url, row.document01.index);
                                }
                            }
                            if (row.document02) {
                                if (row.document02.fileType === 'image') {
                                    let img = _createImageElement(row.document02.index + '_view', row.document02.url, row.document02.index);
                                    img.alt = row.document02.label;
                                    if (row.document02.isType === false) {
                                        img.alt = row.document02.typeLabel + ' : ' + row.document02.label;
                                    }
                                    row.viewers[1] = new Viewer(img, imageViewerParams);
                                } else {
                                    _createObjectElement(row.document02.index + '_view', row.document02.url, row.document02.index);
                                }
                            }
                        }
                    }, 500);

                    function _createImageElement(id, src, parentId) {
                        let parent = document.getElementById(parentId);
                        parent.innerHTML = '';
                        let img = document.createElement('img');
                        img.src = src;
                        img.id = id;
                        img.classList.add('doc-img-viewer');
                        img.style.opacity = 0;
                        parent.appendChild(img);
                        return img;
                    }

                    function _createObjectElement(id, src, parentId) {

                        let parent = document.getElementById(parentId);
                        parent.innerHTML = '';

                        let obj = document.createElement('object');
                        obj.type = 'application/pdf';
                        obj.data = src;

                        let embed = document.createElement('embed');
                        embed.type = 'application/pdf';
                        embed.src = src;
                        embed.style.width = '100%';
                        embed.style.height = '100%';

                        //obj.append(embed);
                        obj.classList.add('doc-pdf-viewer');
                        parent.appendChild(obj);

                    }

                    $scope.toggleLeftSide = () => {
                        $scope.showLeftSide = !$scope.showLeftSide;
                        let t = setTimeout(() => {
                            window.dispatchEvent(new Event('resize'));
                            t = setTimeout(() => {
                                window.dispatchEvent(new Event('resize'));
                                t = setTimeout(() => {
                                    window.dispatchEvent(new Event('resize'));
                                    window.clearTimeout(t);
                                }, 200);
                            }, 200);
                        }, 200);
                    };

                    $scope.tabberNext = (lastItem = false) => {
                        if (!lastItem) {
                            $scope.currentDocumentTab++;
                        } else {
                            let documents = [], index = {};
                            for (let i = 0; i < $scope.documents.length; i++) {
                                let row = $scope.documents[i];
                                let doc01 = row.document01;
                                let doc02 = row.document02;
                                if (doc01 && doc01.status === 'document-uploaded') {
                                    if (!index[doc01._id]) {
                                        index[doc01._id] = {
                                            _id: doc01._id,
                                            comment: doc01.comment
                                        };
                                    }
                                }
                                if (doc02 && doc02.status === 'document-uploaded') {
                                    if (!index[doc02._id]) {
                                        index[doc02._id] = {
                                            _id: doc02._id,
                                            comment: doc02.comment
                                        };
                                    }
                                }
                            }
                            for (let i in index) {
                                documents.push(index[i]);
                            }
                            if (documents.length > 0)
                                procedureService.documentsBulkCheck($scope.procedureId, documents).then(() => {
                                    $mdDialog.hide();
                                    toast.show('Les documents ont bien été mis à jour.', 'success');
                                    $route.reload();
                                }).catch((err) => {
                                    console.log(err);
                                    toast.show(err && err.message ? err.message : DEFAULT_ERROR_MESSAGE, 'error');
                                });

                        }//
                    };
                    $scope.tabberPrev = () => {
                        if ($scope.currentDocumentTab > 0) {
                            $scope.currentDocumentTab--;
                        }
                    };

                    $scope.rejectDocument = (document, comment) => {
                        document.comment = comment;
                        $scope.updateDocument(document);
                        $scope.rejectedDocuments.push(document);
                    };

                    $scope.resetDocumentComment = (document) => {
                        let confirm = $mdDialog.confirm()
                                .multiple(true).title('Accpeter le document')
                                .textContent('Voulez-vous vraiment annuler le commentaire et accepter ce document ?')
                                .targetEvent(event).ok('Oui').cancel('Non').theme('confirm');
                        $mdDialog.show(confirm).then(() => {
                            document.comment = null;
                            $scope.updateDocument(document);
                        }, () => {
                            // cancelled
                        });
                    };

                    $scope.updateDocument = (document) => {
                        for (let i = 0; i < $scope.documents.length; i++) {
                            let row = $scope.documents[i];
                            let doc01 = row.document01;
                            let doc02 = row.document02;
                            if (doc01 && doc01._id === document._id && doc01.index !== document.index) {
                                doc01.comment = document.comment;
                            }
                            if (doc02 && doc02._id === document._id && doc02.index !== document.index) {
                                doc02.comment = document.comment;
                            }
                        }
                    };

                    $scope.showRejectDocPopup = (documentToReject) => {
                        if (verifyProcedureHandle()) {
                            $mdDialog.show({
                                templateUrl: TEMPLATES_DIR + 'new.reject.document.html',
                                parent: angular.element(document.body),
                                fullscreen: true,
                                multiple: true,
                                controller: RejectDocumentCtrl,
                                controllerAs: 'ctrl',
                                locals: {
                                    document: documentToReject
                                }
                            }).then(() => {
                                /* dialog closed */
                            });
                        }//
                    };

                    // update procedure data
                    $scope.edit = ($event, input = {type: 'text', placeholder: '', size: 14}) => {

                        let inputType = input.type;
                        let inputPlaceholder = input.placeholder;
                        let inputSize = input.size;

                        let _this = $event.currentTarget;
                        let parent = $(_this).parent();
                        let item = $(getTargetItem($event));
                        let type = $(item).data('type');
                        let value = $(item).data('value');
                        let field = $(item).data('field');
                        let personSubType = $(item).data('sub-type');
                        if (field === 'personType') {
                            if (personSubType === 'seller') {
                                $scope.editSeller = true;
                                $scope.editedSeller = {
                                    type: personSubType,
                                    personType: value === 'personne-physique' ? 'personne-morale' : 'personne-physique',
                                    isPro: value === 'personne-morale'
                                };
                            } else if (personSubType === 'buyer') {
                                $scope.editBuyer = true;
                                $scope.editedBuyer = {
                                    type: personSubType,
                                    personType: value === 'personne-physique' ? 'personne-morale' : 'personne-physique',
                                    isPro: value === 'personne-morale'
                                };
                            } else if (personSubType === 'owner') {
                                $scope.editOwner = true;
                                $scope.editedOwner = {
                                    type: personSubType,
                                    personType: value === 'personne-physique' ? 'personne-morale' : 'personne-physique',
                                    isPro: value === 'personne-morale'
                                };
                            }
                            return;
                        }
                        if (value === true) {
                            value = 'OUI';
                        } else if (value === false) {
                            value = 'NON';
                        } else if (value === 'personne-physique') {
                            value = 'Particulier';
                        } else if (value === 'personne-morale') {
                            value = 'Société';
                        }
                        let inputFields = {
                            type: inputType,
                            field: type + '.' + field,
                            size: inputSize,
                            value: value,
                            placeholder: inputPlaceholder
                        };
                        if (field === 'saleDate,saleTime') {
                            let saleDate = $(item).data('data-saledate');
                            let saleTime = $(item).data('data-saletime');
                            value = moment(saleDate + ' ' + saleTime, 'DD/MM/YYYY HH:mm', true).format('YYYY-MM-DDTHH:mm');
                            let now = new Date(), minDate = new Date();
                            now.setMilliseconds(0);
                            now.setSeconds(0);
                            minDate.setYear(now.getFullYear() - 50);
                            inputFields.value = value;
                            inputFields.min = moment(minDate).format('YYYY-MM-DDTHH:mm');
                            inputFields.max = moment(now).format('YYYY-MM-DDTHH:mm');
                        }
                        $(item).html('');
                        let inputHTML = '<input ';
                        for (let f in inputFields) {
                            inputHTML += f + '="' + inputFields[f] + '" ';
                        }
                        inputHTML += '>';
                        $(item).append(inputHTML);
                        $(_this).addClass('hidden');
                        $(parent).find($('.btn-validate-edit')).removeClass('hidden');
                        $(parent).find($('.btn-cancel-edit')).removeClass('hidden');
                        if ($(item).hasClass('badge')) {
                            $(item).removeClass('badge');
                            $(item).addClass('badge-off');
                        }//
                    };
                    $scope.editType = ($event, seller = null) => {
                        let item = $(getTargetItem($event));
                        let value = $(item).data('value');
                        let field = $(item).data('field');
                        let personSubType = $(item).data('sub-type');

                        if (field === 'personType') {
                            if (personSubType === 'seller') {
                                $scope.editSeller = true;
                                $scope.editBuyer = $scope.editOwner = false;
                                $scope.editedSeller = {
                                    type: personSubType,
                                    personType: value === 'personne-physique' ? 'personne-morale' : 'personne-physique',
                                    isPro: value === 'personne-morale'
                                };
                            } else if (personSubType === 'buyer') {
                                $scope.editBuyer = true;
                                $scope.editSeller = $scope.editOwner = false;
                                $scope.editedBuyer = {
                                    type: personSubType,
                                    personType: value === 'personne-physique' ? 'personne-morale' : 'personne-physique',
                                    isPro: value === 'personne-morale'
                                };
                            } else if (personSubType === 'owner') {
                                $scope.editOwner = true;
                                $scope.editSeller = $scope.editBuyer = false;
                                $scope.editedOwner = {
                                    type: personSubType,
                                    personType: value === 'personne-physique' ? 'personne-morale' : 'personne-physique',
                                    isPro: value === 'personne-morale'
                                };
                            }
                        }

                        let locals = {
                            editedSeller: $scope.editedSeller,
                            editedBuyer: $scope.editedBuyer,
                            editedOwner: $scope.editedOwner,
                            editBuyer: $scope.editBuyer,
                            editOwner: $scope.editOwner,
                            editSeller: $scope.editSeller,
                            procedure: $scope.procedure,
                            seller: $scope.seller,
                            buyer: $scope.buyer,
                            owner: $scope.owner
                        };
                        $mdDialog.show({
                            parent: angular.element(document.body),
                            targetEvent: event,
                            locals: locals,
                            multiple: true,
                            draggable: true,
                            templateUrl: TEMPLATES_DIR + 'edit-type.html',
                            controller: (
                                    $scope, $mdDialog, toast, $location, editedSeller, editSeller,
                                    editBuyer, editedBuyer, editOwner, editedOwner, procedure, buyer, seller, owner
                                    ) => {
                                $scope.editSeller = editSeller;
                                $scope.editedSeller = editedSeller;
                                $scope.editBuyer = editBuyer;
                                $scope.editedBuyer = editedBuyer;
                                $scope.editOwner = editOwner;
                                $scope.editedOwner = editedOwner;
                                $scope.procedure = procedure;
                                $scope.seller = seller;
                                $scope.buyer = buyer;
                                $scope.owner = owner;

                                $scope.cancelSellerEdit = () => {
                                    $scope.editSeller = false;
                                    $scope.editedSeller = {};
                                    $timeout(() => {
                                        $scope.$apply();
                                    });
                                    $mdDialog.hide();
                                };
                                $scope.saveEditedTypeSeller = () => {
                                    if ($scope.editSeller) {
                                        procedureService.updateItem($scope.procedure.ProcedureId, 'person', $scope.seller._id, $scope.editedSeller).then(() => {
                                            toast.show('Les données ont été bien mises à jour.', 'success');
                                            setTimeout(() => {
                                                $window.location.href = $location.path() + "/?tab=2&&doc=" + document._id;
                                            }, 500);
                                        }).catch((err) => {
                                            toast.show(err && err.message ? err.message : DEFAULT_ERROR_MESSAGE);

                                        });
                                    }
                                };
                                $scope.cancelBuyerEdit = () => {
                                    $scope.editBuyer = false;
                                    $scope.editedBuyer = {};
                                    $timeout(() => {
                                        $scope.$apply();
                                    });
                                    $mdDialog.hide();
                                };
                                $scope.saveEditedTypeBuyer = () => {
                                    if ($scope.editBuyer) {
                                        procedureService.updateItem($scope.procedure.ProcedureId, 'person', $scope.buyer._id, $scope.editedBuyer).then(() => {
                                            toast.show('Les données ont été bien mises à jour.', 'success');
                                            setTimeout(() => {
                                                $window.location.href = $location.path() + "/?tab=2&&doc=" + document._id;
                                            }, 3000);
                                        }).catch((err) => {
                                            toast.show(err && err.message ? err.message : DEFAULT_ERROR_MESSAGE);
                                        });
                                    }
                                };
                                $scope.cancelOwnerEdit = () => {
                                    $scope.editOwner = false;
                                    $scope.editedOwner = {};
                                    $timeout(() => {
                                        $scope.$apply();
                                    });
                                    $mdDialog.hide();
                                };
                                $scope.saveEditedTypeOwner = () => {
                                    if ($scope.editOwner) {
                                        procedureService.updateItem($scope.procedure.ProcedureId, 'person', $scope.owner._id, $scope.editedOwner).then(() => {
                                            toast.show('Les données ont été bien mises à jour.', 'success');
                                            setTimeout(() => {
                                                $window.location.href = $location.path() + "/?tab=2&&doc=" + document._id;
                                            }, 3000);
                                        }).catch((err) => {
                                            toast.show(err && err.message ? err.message : DEFAULT_ERROR_MESSAGE);
                                        });
                                    }
                                };
                            }
                        });
                    };
                    $scope.cancelEdit = ($event) => {
                        let _this = $event.currentTarget,
                                parent = $(_this).parent(),
                                item = $(getTargetItem($event));

                        let value = $(item).data('value');
                        $(item).html(value);

                        $(_this).addClass('hidden');
                        $(parent).find($('.btn-edit')).removeClass('hidden');
                        $(parent).find($('.btn-validate-edit')).addClass('hidden');
                        if ($(item).hasClass('badge-off')) {
                            $(item).removeClass('badge-off');
                            $(item).addClass('badge');
                        }
                    };
                    $scope.validateEdit = ($event, origItem) => {
                        let _this = $event.currentTarget,
                                parent = $(_this).parent(),
                                item = $(getTargetItem($event));
                        let type = $(item).data('type'),
                                value = $(item).data('value') + '',
                                field = $(item).data('field'),
                                itemId = $(item).data('data-id'),
                                itemType = $(item).data('data-type'),
                                itemPersonTitle = $(item).data('data-title'),
                                itemPersonType = $(item).data('data-person-type');

                        let newValue = ($(item).find('input').val() || '').toUpperCase(),
                                company = null, firstname = null, lastname = null;

                        if (newValue === 'PARTICULIER') {
                            newValue = 'personne-physique';
                        } else if (newValue === 'SOCIÉTÉ') {
                            newValue = 'personne-morale';
                            company = prompt('Veuillez taper le nom de la société', '');
                        } else if (newValue === 'OUI' || newValue === 'TRUE') {
                            newValue = true;
                        } else if (newValue === 'NON' || newValue === 'FALSE') {
                            newValue = false;
                        }
                        let data = type === 'person' ? {
                            type: itemType,
                            personType: itemPersonType
                        } : {};
                        if (company !== null && company.length > 1) {
                            data.company = company;
                            console.error('TRY TO SEND SIREN & COMPANY IN THE SAME REQUEST TO AVOID API ERROR MESSAGE !!!');
                            //data.siren = ''
                        }
                        if (['certificat-immatriculation', 'declaration-cession',
                            'declaration-achat'].indexOf(type) > -1) {
                            if (field === 'saleDate,saleTime') {
                                let saleDateTime = moment(newValue);
                                data.saleDate = saleDateTime.format('DD/MM/YYYY');
                                data.saleTime = saleDateTime.format('HH:mm');
                            }
                        }
                        if (field === 'marriedname') {
                            data['title'] = itemPersonTitle;
                        }
                        if (newValue !== value.toUpperCase()) {
                            /*if (['TRUE', 'FALSE', 'OUI', 'NON'].indexOf(newValue) > -1) {
                             newValue = newValue === 'TRUE' || newValue === 'OUI';
                             }*/
                            if (field !== 'saleDate,saleTime') {
                                $(item).data('value', newValue);
                                data[field] = newValue;
                            }
                            procedureService.updateItem($scope.procedure.ProcedureId, type, itemId, data).then(() => {
                                toast.show('Les données ont été bien mises à jour.', 'success');
                                if (typeof newValue === 'boolean') {
                                    $(item).html(newValue ? 'Oui' : 'Non');
                                } else {
                                    if (newValue === 'personne-physique') {
                                        $(item).html('Particulier');
                                    } else if (newValue === 'personne-morale') {
                                        $(item).html('Société');
                                    } else {
                                        $(item).html(newValue);
                                    }
                                }
                                $(item).data('value', newValue);
                                try {
                                    origItem[field] = newValue;
                                } catch (ex) {
                                    console.log(ex);
                                }
                                $(_this).addClass('hidden');
                                $(parent).find($('.btn-edit')).removeClass('hidden');
                                $(parent).find($('.btn-cancel-edit')).addClass('hidden');
                                if ($(item).hasClass('badge-off')) {
                                    $(item).removeClass('badge-off');
                                    $(item).addClass('badge');
                                }
                            }).catch((err) => {
                                toast.show(err && err.message ? err.message : DEFAULT_ERROR_MESSAGE);
                            });
                        }
                    };
                    // update procedure data
                    function RejectDocumentCtrl($mdDialog, document) {
                        var ctrl = this;
                        ctrl.newMotifs = gData.rejectDocumentMotifs;
                        ctrl.comment = document.comment || '';
                        ctrl.motifs = [
                            {label: 'Type incorrect', checked: false},
                            {label: 'Document illisible', checked: false},
                            {label: 'Document raturé', checked: false},
                            {label: 'Signature absente', checked: false},
                            {label: 'Signature non conforme', checked: false},
                            {label: 'Document non daté', checked: false},
                            {label: 'Document n\'est pas barré', checked: false},
                            {label: 'Date invalide', checked: false},
                            {label: 'Document non valable / invalide / expiré', checked: false},
                            {label: 'Date et heure de cession sont incorrectes', checked: false},
                            {label: 'Document surchargé', checked: false}
                        ];

                        ctrl.addMotif = (motif) => {
                            if (motif.checked) {
                                ctrl.comment = ctrl.comment.replace(motif.label, '');
                            } else {
                                ctrl.comment += motif.label + ', ';
                            }
                            if (ctrl.comment.charAt(0) === ',' || ctrl.comment.charAt(0) === ' ') {
                                ctrl.comment = ctrl.comment.substring(1);
                            }
                            ctrl.comment = ctrl.comment.replace(' , ,', ' ,');
                            ctrl.comment = ctrl.comment.replace(', , ', ', ');
                        };

                        ctrl.closeDialog = function (confirm = false) {
                            $mdDialog.hide();
                            if (confirm === true) {
                                $scope.rejectDocument(document, ctrl.comment);
                            }//
                        };
                    }

                    RejectDocumentCtrl.prototype.$onInit = function () {};

                    $scope.closeDialog = () => {
                        $mdDialog.hide();
                    };
                }
            }).then(() => {
                /* dialog closed */
            });
        }
    };
    $scope.getProcedureDocumentsList = () => {
        if (verifyProcedureHandle()) {
            if ($scope.procedure.documentsFields.length === 0) {
                procedureService.getDocuments($scope.procedure._id, $scope._type).then((res) => {
                    if (res && res.DocumentsFields && res.DocumentsFields.$and) {
                        $scope.procedure.documentsFields = [];
                        for (let field in res.DocumentsFields.$and) {
                            let doc = res.DocumentsFields.$and[field];
                            if (doc.label) {
                                let docDetails = {
                                    type: doc.type,
                                    label: doc.label,
                                    field: doc.field,
                                    uploaded: doc.uploaded,
                                    extra: doc.extra || false
                                };
                                if (typeof doc.type === 'undefined') {
                                    docDetails.type = field;
                                }
                                $scope.procedure.documentsFields.push(docDetails);
                            }
                        }
                        $scope.procedure.documentsFields.sort((a, b) => {
                            if (a.label > b.label)
                                return 1;
                            else if (a.label < b.label)
                                return -1;
                            else
                                return 0;
                        });
                        $scope.procedure.documentsFields.sort((a, b) => {
                            if (a.extra === true && !b.extra)
                                return 1;
                            else if (!a.extra && b.extra === true)
                                return -1;
                            else
                                return 0;
                        });
                        $scope.$apply();
                    }
                });
            }
        }
    };
    $scope.getDocumentsUploadLink = () => {
        if (verifyProcedureHandle()) {
            $mdDialog.show({
                templateUrl: TEMPLATES_DIR + 'get.documents.upload.link.html',
                parent: angular.element(document.body),
                fullscreen: true,
                locals: {
                    parentScope: $scope,
                    procedureId: $scope.procedure._id,
                    openExternalLink: openExternalLink,
                    procedureNum: $scope.procedure.number
                },
                controller: ($scope, $mdDialog, toast, parentScope, procedureId, openExternalLink, procedureNum) => {
                    $scope.title = "Lien de téléversement de documents";
                    $scope.procedureNum = procedureNum;
                    $scope.procedureId = procedureId;
                    $scope.openExternalLink = openExternalLink;
                    $scope.link = null;
                    $scope.sendLinkBySMS = () => {
                        let msg = 'Vous pouvez téléverser les documents manquants ' +
                                'pour votre démarche N ' + $scope.procedureNum +
                                ' ici : ' + $scope.link;
                        parentScope.showSMSPopup($scope.procedureId, null, msg);
                    };
                    $scope.getDocumentsUploadLink = () => {
                        procedureService.getDocumentsUploadLink($scope.procedureId).then((data) => {
                            $scope.link = data.documentsUploadLink;
                            $scope.$apply();
                        }).catch((err) => {
                            toast.show(err && err.message ? err.message : DEFAULT_ERROR_MESSAGE, 'error', false, 7500);
                        });
                    };
                    $scope.closeDialog = () => {
                        $mdDialog.hide();
                    };
                }
            });
        }
    };
    $scope.getReceiptDownloadLink = () => {
        if (verifyProcedureHandle()) {
            $mdDialog.show({
                templateUrl: TEMPLATES_DIR + 'get.documents.upload.link.html',
                parent: angular.element(document.body),
                fullscreen: true,
                locals: {
                    parentScope: $scope,
                    procedureId: $scope.procedure._id,
                    openExternalLink: openExternalLink,
                    procedureNum: $scope.procedure.number
                },
                controller: ($scope, $mdDialog, toast, parentScope, procedureId, openExternalLink, procedureNum) => {
                    $scope.title = "Lien de téléchargement de l'accusé d'enregistrement / CPI";
                    $scope.procedureNum = procedureNum;
                    $scope.procedureId = procedureId;
                    $scope.openExternalLink = openExternalLink;
                    $scope.link = null;
                    $scope.sendLinkBySMS = () => {
                        let msg = "Vous pouvez télécharger l'accusé d'enregistrement / CPI " +
                                'de votre démarche N ' + $scope.procedureNum +
                                ' ici : ' + $scope.link;
                        parentScope.showSMSPopup($scope.procedureId, null, msg);
                    };
                    $scope.getDocumentsUploadLink = () => {
                        procedureService.getReceiptDownloadLink($scope.procedureId).then((data) => {
                            $scope.link = data.receiptDownloadLink;
                            $scope.$apply();
                        }).catch((err) => {
                            toast.show(err && err.message ? err.message : DEFAULT_ERROR_MESSAGE, 'error', false, 7500);
                        });
                    };
                    $scope.closeDialog = () => {
                        $mdDialog.hide();
                    };
                }
            });
        }
    };

//----------------------------------------------------- procedure operations ---

    $scope.dup = {
        init: function () {
            /*setTimeout( () => {
             this.hasCerfaEditable = true;
             }, 3000);*/
        },
        saveData: function (field, value) {
            if (verifyProcedureHandle(true)) {
                let data = {};
                data[field] = value;
                procedureService.updateItem($scope.procedureId, $scope._type, $scope.item._id, data).then(() => {
                    toast.show('Les données ont été bien mises à jour.', 'success');
                    for (let i in this) {
                        if ((i + '').indexOf('Editable') > 0) {
                            this[i] = false;
                        }
                    }
                    $timeout(() => {
                        $scope.$apply();
                    });
                }).catch((err) => {
                    toast.show(err && err.message ? err.message : DEFAULT_ERROR_MESSAGE);
                });
            }
        }
    };

    $scope.dup.init();

    $scope.notify = (procedure, type = null) => {
        if (verifyProcedureHandle()) {
            let what = type === 'missing-documents' ? 'le téléversement des documents' :
                    'l\'envoi ou le dépôt de l\'ancien certificat d\'immatriculation';
            procedureService.notify(procedure._id, type).then((result) => {
                toast.show('Une notification de relance pour ' + what +
                        ' a bien été envoyée au client.', 'success');
            }).catch((err) => {
                toast.show(err && err.message ? err.message : DEFAULT_ERROR_MESSAGE);
                console.error(err);
            });
        }//
    };
    $scope.sendToTMS = (procedureId) => {
        if (verifyProcedureHandle()) {
            procedureService.sendToTMS(procedureId).then((response) => {
                toast.show('La démarche a bien été envoyée à TMS. ' +
                        '<br>' + 'Un e-mail contenant l\'accusé d\'enregistrement à bien été envoyé au client.' +
                        '<br>' + 'Ce document est disponible dans la liste des documents de la démarche. ', 'success', true, 30000);
                $location.search({});
                $route.reload();
            }).catch((err) => {
                console.error('error');
                console.error(err);
                toast.show(err && err.message ? err.message : DEFAULT_ERROR_MESSAGE, 'error', true, 30000);
            });
        }
    };
    $scope.createDC = (procedureId) => {
        if (verifyProcedureHandle()) {
            if ($scope.item.saleDate === null || $scope.item.saleTime === null) {
                toast.show('Il faut préciser la date et l\'heure de cession ' +
                        'pour pouvoir faire la déclaration de cession.', 'error', true);
                return;
            }
            procedureService.createDC(procedureId).then((result) => {
                toast.show('La déclaration de cession a bien été faite.', 'success');
                $location.search({});
            }).catch((err) => {
                toast.show(err && err.message ? err.message : DEFAULT_ERROR_MESSAGE, 'error');
                $location.search({});
                $route.reload();
            });
        }
    };
    $scope.validate = (procedureId) => {
        if (verifyProcedureHandle()) {
            if ($scope.item.saleDate === null || $scope.item.saleTime === null) {
                toast.show('Il faut préciser la date et l\'heure de cession ' +
                        'pour la démarche avant de la valider.', 'error', true);
                return;
            }
            procedureService.validate(procedureId).then(() => {
                toast.show('La procédure a bien été validée.', 'success');
                $location.search({});
                $route.reload();
            }).catch((err) => {
                toast.show(err && err.message ? err.message : DEFAULT_ERROR_MESSAGE, 'error');
                $location.search({});
                $route.reload();
            });
        }
    };
    $scope.reject = (procedureId) => {
        if (verifyProcedureHandle()) {
            $mdDialog.show({
                parent: angular.element(document.body),
                template:
                        `<md-dialog layout-padding flex="35" aria-label="List dialog">
                    <md-dialog-content layout="column">
                        <div>
                            <h3 style='margin: 0; margin-bottom: 10px;'>
                                <md-icon style='font-size: 24px; color: red'>
                                    assignment_turned_in
                                </md-icon>
                                Confirmation
                            </h3>
                        </div>
                        <md-divider></md-divider>
                        <br>
                        <span>
                            Voulez-vous confirmer le refus de la démarche ?
                        </span><br>
                        <md-input-container class="md-block" 
                                style='background-color: #f7f7f7; padding: 10px; 
                                    border: 1px solid #e0e0e0; border-radius: 5px;'>
                                <label>Commentaire / message</label>
                                <textarea ng-model="rejectionComment" 
                                    md-maxlength="255" rows="3"></textarea>
                            </md-input-container>
                        </md-dialog-content>
                    <md-dialog-actions>
                        <div class='buttons-container'>
                            <md-button ng-click="closeDialog()"
                                class="md-button green btn">Annuler</md-button>
                            <md-button ng-click="closeDialog(true)" 
                                class="btn btn-submit red">Confirmer</md-button>
                        </div>
                    </md-dialog-actions>
                  </md-dialog>`,
                controller: ($scope, $mdDialog, toast) => {
                    $scope.closeDialog = (confirm = false) => {
                        $mdDialog.hide();
                        if (confirm) {
                            procedureService.reject(procedureId, $scope.rejectionComment).then((result) => {
                                toast.show('Cette procédure a bien été refusée et' +
                                        ' le client a reçu une notification.', 'success');
                                $route.reload();
                            }).catch((err) => {
                                toast.show(err && err.message ? err.message : DEFAULT_ERROR_MESSAGE, 'error');
                            });
                        }//
                    };
                }
            });
        }
    };
    $scope.unreject = (procedureId) => {
        if (verifyProcedureHandle()) {
            $mdDialog.show({
                parent: angular.element(document.body),
                template:
                        `<md-dialog layout-padding flex="35" aria-label="List dialog">
                    <md-dialog-content layout="column">
                        <div>
                            <h3 style='margin: 0; margin-bottom: 10px;'>
                                <md-icon style='font-size: 24px; color: red'>
                                    assignment_turned_in
                                </md-icon>
                                Confirmation
                            </h3>
                        </div>
                        <md-divider></md-divider>
                        <br>
                        <span>
                            Voulez-vous confirmer la réactivation de la démarche ?
                        </span>
                        </md-dialog-content>
                    <md-dialog-actions>
                        <div class='buttons-container'>
                            <md-button ng-click="closeDialog()"
                                class="md-button green btn">Annuler</md-button>
                            <md-button ng-click="closeDialog(true)" 
                                class="btn btn-submit red">Confirmer</md-button>
                        </div>
                    </md-dialog-actions>
                  </md-dialog>`,
                controller: ($scope, $mdDialog, toast) => {
                    $scope.closeDialog = (confirm = false) => {
                        $mdDialog.hide();
                        if (confirm) {
                            procedureService.unreject(procedureId).then((result) => {
                                toast.show('Cette procédure a bien été réactivée.', 'success');
                                $route.reload();
                            }).catch((err) => {
                                toast.show(err && err.message ? err.message : DEFAULT_ERROR_MESSAGE, 'error');
                            });
                        }//
                    };
                }
            });
        }
    };
    $scope.archive = (procedureId) => {
        if (verifyProcedureHandle()) {
            $mdDialog.show({
                parent: angular.element(document.body),
                template:
                        `<md-dialog layout-padding flex="35" aria-label="List dialog">
                    <md-dialog-content layout="column">
                        <div>
                            <h3 style='margin: 0; margin-bottom: 10px;'>
                                <md-icon style='font-size: 24px; color: red'>
                                    assignment_turned_in
                                </md-icon>
                                Confirmation
                            </h3>
                        </div>
                        <md-divider></md-divider>
                        <br>
                        <span>
                            Voulez-vous vraiment archiver cette démarche ?
                        </span><br>
                        </md-dialog-content>
                    <md-dialog-actions>
                        <div class='buttons-container'>
                            <md-button ng-click="closeDialog()"
                                class="md-button green btn">Annuler</md-button>
                            <md-button ng-click="closeDialog(true)" 
                                class="btn btn-submit red">Confirmer</md-button>
                        </div>
                    </md-dialog-actions>
                  </md-dialog>`,
                controller: ($scope, $mdDialog, toast) => {
                    $scope.closeDialog = (confirm = false) => {
                        $mdDialog.hide();
                        if (confirm) {
                            procedureService.archive(procedureId).then(() => {
                                toast.show('Cette procédure a bien été archivée.', 'success');
                                $route.reload();
                            }).catch((err) => {
                                toast.show(err && err.message ? err.message :
                                        'Une erreur est survenue lors du ' +
                                        'traitement de votre demande.', 'error');
                            });
                        }//
                    };
                }
            });
        }
    };
    $scope.getSituationAdministrative = (procedureId) => {
        if (verifyProcedureHandle()) {
            $mdDialog.show({
                templateUrl: TEMPLATES_DIR + 'situation.administrative.html',
                parent: angular.element(document.body),
                fullscreen: true,
                locals: {
                    procedureId: procedureId,
                    seller: $scope.seller
                },
                controller: ($scope, $mdDialog, toast, procedureId, seller) => {
                    let name = seller.company || (seller.lastname + ' ' + seller.firstname);
                    $scope.procedureId = procedureId;
                    $scope.type = null;
                    $scope.documentURL = null;
                    $scope.personType = seller.personType;
                    $scope.personTypeEdited = false;
                    $scope.name = name;
                    $scope.initName = name;
                    $scope.nameEdited = false;
                    $scope.onTypeChange = () => {
                        $scope.documentURL = null;
                    };
                    $scope.onPersonTypeChange = () => {
                        $scope.personTypeEdited = true;
                        $scope.name = null;
                    };
                    $scope.onNameChange = () => {
                        $scope.nameEdited = true;
                    };
                    $scope.closeDialog = (confirm = false) => {
                        $mdDialog.hide();
                    };
                    $scope.run = () => {
                        let data = {
                            personType: $scope.personType,
                            name: $scope.name
                        };
                        procedureService.getSituationAdministrative(procedureId, $scope.type, data).then((result) => {
                            if (typeof result.documentURL === 'string') {
                                $scope.documentURL = result.documentURL;
                                $scope.$apply();
                            } else {
                                if (result.situationOK === true) {
                                    toast.show('Le véhicule ne présente aucun gage ou opposition', 'success');
                                } else {
                                    toast.show('Le véhicule présente un gage ou une opposition', 'error');
                                }
                            }
                        }).catch((err) => {
                            console.log(err);
                            toast.show(err && err.message ? err.message : DEFAULT_ERROR_MESSAGE, 'error');
                        });
                    };
                }
            }).then(() => {
            });
        }
    };
    $scope.getFicheIdentificationVehicule = (procedureId) => {
        if (verifyProcedureHandle()) {
            $mdDialog.show({
                templateUrl: TEMPLATES_DIR + 'fiche.identification.html',
                parent: angular.element(document.body),
                fullscreen: true,
                locals: {
                    procedureId: procedureId,
                    seller: $scope.seller || $scope.owner
                },
                controller: ($scope, $mdDialog, toast, procedureId, seller) => {
                    $scope.procedureId = procedureId;
                    $scope.documentURL = null;
                    $scope.personType = seller.personType;
                    $scope.personTypeEdited = false;
                    $scope.name = seller.company || (seller.lastname + ' ' + seller.firstname);
                    $scope.initName = seller.company || (seller.lastname + ' ' + seller.firstname);
                    $scope.nameEdited = false;
                    $scope.onTypeChange = () => {
                        $scope.documentURL = null;
                    };
                    $scope.onPersonTypeChange = () => {
                        $scope.personTypeEdited = true;
                        $scope.name = null;
                    };
                    $scope.onNameChange = () => {
                        $scope.nameEdited = true;
                    };
                    $scope.closeDialog = () => {
                        $mdDialog.hide();
                    };
                    $scope.run = () => {
                        let data = {
                            personType: $scope.personType,
                            name: $scope.name
                        };
                        procedureService.getFicheIdentificationVehicule(procedureId, data).then((result) => {
                            if (typeof result.documentURL === 'string') {
                                $scope.documentURL = result.documentURL;
                                $scope.$apply();
                            }
                        }).catch((err) => {
                            console.log(err);
                            toast.show(err && err.message ? err.message : DEFAULT_ERROR_MESSAGE, 'error');
                        });
                    };
                }
            }).then(() => {
                /* dialog closed */
            });
        }
    };
    $scope.getVehicleInfos = (vehicleRegNum = '') => {
        procedureTools.getVehicleInfos(vehicleRegNum).then((infos) => {
            let s = '<div class="no-padding-top padding p20">';
            s += '<h3>' + vehicleRegNum + '</h3><hr>';
            for (let i in infos) {
                let v = infos[i];
                if (v.label !== '') {
                    s += '<p>' + '<span style="display:inline-block;width:260px;font-weight:700">' +
                            v.label + '</span> ' + v.value + '</p>';
                }
            }
            s += '</div>';
            $mdDialog.show({
                controller: function () {},
                template: s,
                parent: angular.element(document.body),
                clickOutsideToClose: true
            });
        }).catch((err) => {
            console.log(err);
            toast.show(err && err.message ? err.message : DEFAULT_ERROR_MESSAGE, 'error', false, 7500);
        });
    };

    //------------------------------------------------------ ants procedures ---
    $scope.setUnsetANTSProcedure = (procedureId, unset = false) => {
        if (verifyProcedureHandle()) {
            if (unset === true) {
                let confirm = $mdDialog.confirm()
                        .title('Annuler une démarche ANTS')
                        .textContent('Voulez-vous vraiment annuler cette démarche ANTS ?')
                        .targetEvent(event)
                        .ok('Oui')
                        .cancel('Non')
                        .theme('confirm');
                $mdDialog.show(confirm).then(() => {
                    ants.setUnsetANTSProcedure($scope.procedureId, $scope.antsProcedure, true).then(() => {
                        toast.show('La démarche ANTS a bien été annulée.', 'success');
                        $route.reload();
                    }).catch((err) => {
                        toast.show(err && err.message ? err.message : DEFAULT_ERROR_MESSAGE, 'error');
                    });
                }, () => {
                    // cancelled
                });
            } else {
                getANTSProcedureMotifs((antsProcedureMotifs) => {
                    $mdDialog.show({
                        templateUrl: TEMPLATES_DIR + 'declare.ants.procedure.html',
                        parent: angular.element(document.body),
                        fullscreen: true,
                        locals: {
                            procedureId: procedureId,
                            antsProcedureMotifs: antsProcedureMotifs
                        },
                        controller: ($scope, $mdDialog, toast, procedureId, antsProcedureMotifs) => {
                            $scope.procedureId = procedureId;
                            $scope.antsProcedureMotifs = antsProcedureMotifs;
                            $scope.antsProcedure = {};
                            $scope.closeDialog = (confirm = false) => {
                                $mdDialog.hide();
                                if (confirm) {
                                    ants.setUnsetANTSProcedure($scope.procedureId, $scope.antsProcedure).then(() => {
                                        toast.show('La démarche a bien été déclarée comme une démarche ANTS.', 'success');
                                        $route.reload();
                                    }).catch((err) => {
                                        toast.show(err && err.message ? err.message : DEFAULT_ERROR_MESSAGE, 'error');
                                    });
                                }//
                            };
                        }
                    });
                });
            }
        }//
    };
    $scope.getProcedureExport = (procedureId) => {
        if (verifyProcedureHandle(true, $scope.hasANTSFullPermission)) {
            procedureService.exportDataAndDocs(procedureId).then((res) => {
                if (res && res.data) {
                    let blob = new Blob([res.data], {type: 'application/zip'});
                    let link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }
            }).catch((err) => {
                console.log(err + '')

                toast.show(err && err.message ? err.message : DEFAULT_ERROR_MESSAGE, 'error');
            });
        }
    };
    $scope.sendANTSProcedure = (procedureId) => {
        if (verifyProcedureHandle(true, $scope.hasANTSFullPermission)) {
            $mdDialog.show({
                templateUrl: TEMPLATES_DIR + 'send.ants.procedure.html',
                parent: angular.element(document.body),
                fullscreen: true,
                locals: {
                    procedureId: procedureId
                },
                controller: ($scope, $mdDialog, toast, procedureId) => {
                    let now = new Date(), minDate = new Date();
                    now.setMilliseconds(0);
                    minDate.setMonth(now.getMonth - 36);

                    $scope.sentAt = now;
                    $scope.maxDate = now;
                    $scope.minDate = minDate;

                    $scope.procedureId = procedureId;
                    $scope.antsProcedureNum = null;

                    $scope.closeDialog = (confirm = false) => {
                        $mdDialog.hide();
                        if (confirm) {
                            if ($scope.antsProcedureNum !== null) {
                                ants.sendANTSProcedure($scope.procedureId, {
                                    antsProcedureNum: $scope.antsProcedureNum,
                                    sentAt: moment($scope.sentAt).format(config.date.defaultExtendedFormat)
                                }).then(() => {
                                    toast.show('La démarche ANTS a bien été mise à jour.', 'success');
                                    $route.reload();
                                }).catch((err) => {
                                    toast.show(err && err.message ? err.message : DEFAULT_ERROR_MESSAGE, 'error');
                                });
                            }
                        }//
                    };
                }
            });
        }
    };
    $scope.showANTSProcedure = (antsProcedure) => {
        if (verifyProcedureHandle(true, $scope.hasANTSGetPermission)) {
            $mdDialog.show({
                templateUrl: TEMPLATES_DIR + 'show.ants.procedure.html',
                parent: angular.element(document.body),
                fullscreen: true,
                locals: {
                    antsProcedure: antsProcedure
                },
                controller: ($scope, $mdDialog, antsProcedure) => {
                    $scope.antsProcedure = antsProcedure;
                    $scope.antsProcedure.link = config.antsProcedureResumeLink +
                            antsProcedure.externalProcedureNum;
                    setTimeout(() => {
                        $scope.$apply();
                    });
                    $scope.closeDialog = () => {
                        $mdDialog.hide();
                    };
                }
            });
        }
    };
    $scope.validateANTSProcedure = (procedureId) => {
        $mdDialog.show({
            templateUrl: TEMPLATES_DIR + 'validate.ants.procedure.html',
            clickOutsideToClose: false,
            locals: {
                procedureId: procedureId,
                document: {
                    field: 'accuse-enregistrement'
                }
            },
            controller: ($scope, $mdDialog, ants, procedureId, document) => {
                $scope.procedureId = procedureId;
                $scope.document = null;
                $scope.onFileSelect = (files, target) => {
                    let file = files[0];
                    if (!file) {
                        return;
                    }
                    $scope.$apply(function () {
                        $scope.document = files[0];
                    });
                };
                $scope.closeDialog = (validated = false, form = null) => {
                    if (!validated) {
                        $mdDialog.cancel();
                    } else {
                        if (!form.$valid || !$scope.document) {
                            return;
                        }
                        let formData = new FormData();
                        formData.append(document.field, $scope.document);
                        $mdDialog.hide(ants.validateANTSProcedure($scope.procedureId, formData));
                    }//
                };
            }
        }).then((res) => {
            if (res) {
                toast.show('Le document a bien été téléversé.', 'success');
                $location.search('tab', 2);
                $route.reload();
            }
        }).catch((err) => {
            console.error('upload document error :');
            console.log(err);
            toast.show(err, 'error', true);
        });

    };
    function getANTSProcedureMotifs(cb) {
        if (verifyProcedureHandle()) {
            let data = JSON.parse(localStorage.getItem('antsProcedureMotifs'));
            if (!data) {
                ants.getANTSProcedureMotifs().then((_data) => {
                    localStorage.setItem('antsProcedureMotifs', JSON.stringify(_data));
                    if (typeof cb === 'function') {
                        cb(_data);
                    }
                    $scope.$apply();
                }).catch((err) => {
                    //
                });
            } else {
                if (typeof cb === 'function') {
                    cb(data);
                }
            }
        }
    }

    //--------------------------------------------------------------------------

    $scope.showPaymentPopup = (procedureId, amount = null, motif = null) => {
        if (verifyProcedureHandle()) {
            $mdDialog.show({
                templateUrl: TEMPLATES_DIR + 'create.procedure.payment.html',
                parent: angular.element(document.body),
                fullscreen: true,
                locals: {
                    parentScope: $scope,
                    totalToPay: $scope.item.totalToPay,
                    procedureId: procedureId || $scope.procedure._id,
                    isMobileApp: $scope.procedure.origin.name === 'app-mobile',
                    paid: $scope.procedure.paid && $scope.item.paid,
                    paidTaxes: $scope.item.paidTaxes,
                    hasTaxes: $scope.hasTaxes,
                    amountToPay: amount,
                    paymentMotif: motif
                },
                controller: ($scope, $mdDialog, toast, parentScope, procedureId, totalToPay, isMobileApp, paid, hasTaxes, paidTaxes, amountToPay, paymentMotif) => {
                    $scope.hasTaxes = hasTaxes;
                    $scope.procedureId = procedureId;
                    $scope.amountToPay = amountToPay || totalToPay;
                    $scope.paymentMotif = paymentMotif || null;
                    $scope.paymentLink = null;
                    $scope.isMobileApp = isMobileApp;
                    $scope.paid = paid;
                    $scope.paidTaxes = paidTaxes;
                    if ($scope.isMobileApp) {
                        $scope.paymentMethod = 'carte-bancaire';
                    }
                    $scope.createPayment = () => {
                        procedureService.createPayment($scope.procedureId, {
                            amount: $scope.amountToPay,
                            method: $scope.paymentMethod,
                            motif: $scope.paymentMotif
                        }).then((data) => {
                            if (data.redirectURL) {
                                $scope.paymentLink = data.redirectURL;
                            }
                            if (data.message) {
                                toast.show(data.message, 'success', false, 7500);
                            }
                            $scope.$apply();
                        }).catch((err) => {
                            toast.show(err && err.message ? err.message : DEFAULT_ERROR_MESSAGE, 'error', false, 7500);
                        });
                    };
                    $scope.sendLinkBySMS = () => {
                        parentScope.showSMSPopup($scope.procedureId, null, $scope.paymentLink);
                    };
                    $scope.closeDialog = (confirm = false) => {
                        $mdDialog.hide();
                        if (confirm) {

                        }//
                    };
                }
            }).then(() => {
            }, () => {
            });
    }
    };
    $scope.getPayments = (procedureId = null, itemId = null) => {
        if ($scope.item.payments.length > 0) {
            return;
        }
        procedureService.getPayments(procedureId, itemId).then((data) => {
            $scope.item.payments = data.NewPayments;
            for (let i = 0; i < $scope.item.payments.length; i++) {
                let payment = $scope.item.payments[i];
                payment.methodText = procedureTools.getProcedurePaymentSupportLabel(payment);
                payment.methodDetails = procedureTools.getProcedurePaymentMethodLabel(payment);
                payment.motifText = procedureTools.getProcedurePaymentMotifLabel(payment);
            }
        }).catch((err) => {
            toast.show(err && err.message ? err.message : DEFAULT_ERROR_MESSAGE, 'error', false, 7500);
        });
    };

    $scope.saveProcedurePayment = (procedureId) => {
        if (verifyProcedureHandle()) {
            $mdDialog.show({
                templateUrl: TEMPLATES_DIR + 'save.procedure.payment.html',
                parent: angular.element(document.body),
                fullscreen: true,
                locals: {
                    procedureId: procedureId,
                    totalToPay: $scope.item.totalToPay
                },
                controller: ($scope, $mdDialog, toast, procedureId, totalToPay) => {
                    $scope.totalToPay = totalToPay;
                    $scope.amountPaid = totalToPay;
                    let now = new Date();
                    now.setMilliseconds(0);
                    now.setSeconds(0);
                    $scope.transactionDateTime = now;
                    $scope.closeDialog = (confirm = false) => {
                        if (confirm) {
                            let transactionDateTime = moment($scope.transactionDateTime).format('YYYY-MM-DD HH:mm:ss');
                            procedureService.savePayment(procedureId, {
                                amountPaid: $scope.amountPaid,
                                transactionDateTime: transactionDateTime,
                                transactionNumber: $scope.transactionNumber
                            }).then(() => {
                                $mdDialog.hide();
                                toast.show('La démarche a bien été mise à jour.', 'success');
                                $route.reload();
                            }).catch((err) => {
                                console.log(err);
                                toast.show(err && err.message ? err.message : DEFAULT_ERROR_MESSAGE, 'error');
                            });
                        } else {
                            $mdDialog.hide();
                    }
                    };
                }
            }).then(() => {
            }, () => {
            });
        }
    };
    $scope.getRefund = (procedureId = null) => {
        if ($scope.item.refund) {
            return;
        }
        procedureService.getRefund(procedureId).then((refund) => {
            if (typeof refund !== 'string') {
                refund.statusLabel = refund.status === 'new' ? 'Créé' : 'Terminé';
                refund.methodLabel = procedureTools.getRefundMethodLabel(refund.method);
                refund.typeLabel = procedureTools.getRefundTypeLabel(refund.type);
                $scope.item.refund = refund;
                $scope.procedure.hasRefund = true;
                if (refund.done === true) {
                    $scope.procedure.refunded = refund.type !== 'partiel';
                    $scope.procedure.partiallyRefunded = refund.type === 'partiel';
                } else {
                    $scope.procedure.pendingPartielRefund = refund.type === 'partiel';
                    $scope.procedure.pendingRefund = true;
                }
                /*$scope.procedure.hasRefund = true;
                 $scope.procedure.hasPartialRefund = refund.type === 'partiel';
                 if (refund.status === 'new') {
                 $scope.procedure.pendingRefund = true;
                 }*/
            }
        }).catch((err) => {
            toast.show(err && err.message ? err.message : DEFAULT_ERROR_MESSAGE, 'error', false, 7500);
        });
    };
    $scope.addRefund = (procedureId, amount = null, type = null) => {
        if (verifyProcedureHandle()) {
            $mdDialog.show({
                templateUrl: TEMPLATES_DIR + 'refund.html',
                parent: angular.element(document.body),
                fullscreen: true,
                locals: {
                    procedureId: procedureId,
                    totalPaid: $scope.item.totalToPay,
                    totalTaxes: $scope.item.totalTaxes || 0,
                    amountToRefund: amount,
                    refundType: type
                },
                controller: ($scope, $mdDialog, toast, procedureTools, procedureId, totalPaid, totalTaxes, amountToRefund, refundType) => {
                    $scope.procedureId = procedureId;
                    $scope.refund = {};
                    $scope.refund.totalPaid = totalPaid;
                    $scope.refund.totalTaxes = totalTaxes;
                    $scope.refund.totalToRefund = amountToRefund || totalTaxes;
                    $scope.defaultMotifs = [
                        "Le client n'a pas tous les documents nécessaires pour la démarche",
                        "Le contrôle technique n'est pas / plus valide",
                        "Le client s'est trompé de la démarche",
                        "Carte grise étrangère",
                        "Permis étranger",
                        "La démarche est bloquée à cause d'une carte grise non faite",
                        "Le véhicule est gagé",
                        "Démarche ou paiement en double",
                        "Paiement des taxes en trop",
                        "Démarche déjà faite ailleurs",
                        "Autre motif (laisser un commentaire)"
                    ];

                    if (amountToRefund !== null) {
                        $scope.refund.type = refundType;
                        $scope.seletedMotif = $scope.defaultMotifs[8];
                        $scope.refund.motif = $scope.seletedMotif;
                    }

                    $scope.onRefundTypeChange = () => {
                        if ($scope.refund.type === 'total') {
                            $scope.refund.totalToRefund = $scope.refund.totalPaid;
                        } else if ($scope.refund.type === 'taxes-uniquement') {
                            $scope.refund.totalToRefund = $scope.refund.totalTaxes;
                        } else if ($scope.refund.type === 'partiel') {
                            $scope.refund.totalToRefund = $scope.refund.totalTaxes;
                        }
                    };
                    $scope.closeDialog = (confirm = false) => {
                        $mdDialog.hide();
                        if (confirm) {
                            procedureService.createRefund($scope.procedureId, $scope.refund).then(() => {
                                toast.show('La demande de remboursement a bien été enregistrée.', 'success');
                                $route.reload();
                            }).catch((err) => {
                                console.log(err);
                                toast.show(err && err.message ? err.message : DEFAULT_ERROR_MESSAGE, 'error');
                            });
                        }//
                    };
                    $scope.isValidIBAN = () => {
                        return procedureTools.isValidIBAN($scope.refund.iban);
                    };
                }
            }).then(() => {
            }, () => {
            });
    }
    };

    $scope.showAttachProcedureToClientPopup = (procedureId, clientId) => {
        if (verifyProcedureHandle()) {
            $mdDialog.show({
                templateUrl: TEMPLATES_DIR + 'attach.procedure.to.client.html',
                parent: angular.element(document.body),
                fullscreen: true,
                locals: {
                    procedureId: procedureId,
                    currentClientId: clientId
                },
                controller: ($scope, $mdDialog, toast, procedureId, currentClientId) => {
                    $scope.procedureId = procedureId;
                    $scope.currentClientId = currentClientId;
                    $scope.selectedClient = null;
                    $scope.searchClient = (searchValue) => {
                        if (searchValue.length < 3) {
                            return [];
                        }
                        return clients.get({
                            name: searchValue
                        }).then((result) => {
                            return result.items;
                        }).catch((err) => {
                            console.error(err);
                        });
                    };
                    $scope.closeDialog = (confirm = false) => {
                        if (confirm) {
                            if ($scope.selectedClient === null) {
                                toast.show('Il faut sélectionner un compte client.', 'error');
                                return;
                            }
                            procedureService.attachToClient(procedureId, {
                                clientId: $scope.selectedClient._id,
                                currentClientId: $scope.currentClientId
                            }).then((res) => {
                                $mdDialog.hide();
                                toast.show(res && res.message ? res.message : 'La démarche a bien été attachée au compte client sélectionné.', 'success');
                                $route.reload();
                            }).catch((err) => {
                                console.log(err);
                                toast.show(err && err.message ? err.message : DEFAULT_ERROR_MESSAGE, 'error');
                            });
                        } else {
                            $mdDialog.hide();
                        }//
                    };
                }
            }).then(() => {
            }, () => {
            });
        }
    };
    $scope.showFraudPopup = (procedureId) => {
        if (verifyProcedureHandle()) {
            $mdDialog.show({
                templateUrl: TEMPLATES_DIR + 'declare.fraud.html',
                parent: angular.element(document.body),
                fullscreen: true,
                locals: {
                    procedureId: procedureId
                },
                controller: ($scope, $mdDialog, toast, procedureId) => {
                    $scope.procedureId = procedureId;
                    $scope.fraud = {};
                    $scope.closeDialog = (confirm = false) => {
                        $mdDialog.hide();
                        if (confirm) {
                            procedureService.declareFraud($scope.procedureId, $scope.fraud).then(() => {
                                toast.show('La déclaration a bien été enregistrée.', 'success');
                                $route.reload();
                            }).catch((err) => {
                                toast.show(err && err.message ? err.message : DEFAULT_ERROR_MESSAGE, 'error');
                            });
                        }//
                    };
                }
            }).then(() => {
            }, () => {
            });
        }
    };
    $scope.getHistory = (procedureId = null) => {
        if ($scope.procedure.history.length > 0) {
            return;
        }
        procedureService.getHistory(procedureId).then((history) => {
            $scope.procedure.history = history;
            for (let i = 0; i < history.length; i++) {
                let historyITem = history[i];
            }
        }).catch((err) => {
            toast.show(err && err.message ? err.message : DEFAULT_ERROR_MESSAGE, 'error', false, 7500);
        });
    };

    //------------------------------------------------------------- comments ---

    $scope.showCommentPopup = (procedure) => {
        if (verifyProcedureHandle()) {
            $mdDialog.show({
                templateUrl: TEMPLATES_DIR + 'post.comment.html',
                parent: angular.element(document.body),
                locals: {
                    procedure: procedure
                },
                controller: ($scope, $mdDialog, toast, procedure) => {
                    $scope.defaultComments = [
                        "La demande a été envoyée a l'ANTS",
                        "La demande va être envoyer a l'ANTS à cause d'un permis étranger",
                        "La demande est bloquée à cause d'une carte grise non faite",
                        "La demande a déjà été validée a l'ANTS",
                        "Le client réclame le remboursement",
                        "La demande de remboursement a été enregistrée sous le N°",
                        "La demande de remboursement est en cours",
                        "Le remboursement a bien été envoyé",
                        "Le véhicule est gagé",
                        "La démarche est en attente de documents"
                    ];
                    $scope.procedure = procedure;
                    $scope.important = false;
                    $scope.selectedComment = '';
                    $scope.setComment = () => {
                        if ($scope.selectedCommment !== '') {
                            $scope.comment = $scope.selectedComment + '.';
                        }
                    };
                    $scope.closeDialog = (confirm = false) => {
                        $mdDialog.hide();
                        if (confirm) {
                            procedureService.comment($scope.procedure._id, {
                                comment: $scope.comment,
                                important: $scope.important
                            }).then((res) => {
                                if (typeof $scope.procedure.comments !== 'undefined') {
                                    res.User = {
                                        name: $rootScope.currentUser.name
                                    };
                                    $scope.procedure.comments.unshift(res);
                                }
                                toast.show(res && res.message ? res.message : 'Votre commentaire a bien été enregistré.', 'success');
                            }).catch((err) => {
                                toast.show(err && err.message ? err.message : DEFAULT_ERROR_MESSAGE, 'error');
                            });
                        }//
                    };
                }
            });
        }
    };
    $scope.deleteComment = (event, procedureId, commentId) => {
        if (verifyProcedureHandle()) {
            var confirm = $mdDialog.confirm()
                    .title("Suppression d'un commentaire")
                    .textContent('Voulez-vous vraiment supprimer ce commentaire ?')
                    .targetEvent(event)
                    .ok('Oui')
                    .cancel('Non')
                    .theme('confirm');
            $mdDialog.show(confirm).then(() => {
                if (confirm) {
                    procedureService.deleteComment(procedureId, commentId).then(() => {
                        toast.show('Le commentaire a bien été supprimé.', 'success');
                        $scope.getComments(procedureId);
                    }).catch((err) => {
                        console.log(err);
                        toast.show('Une erreur est survenue lors de la suppression de ce commentaire.');
                    });
                }//
            }, () => {
            });
        }
    };
    $scope.getComments = (procedureId = null) => {
        procedureService.getComments(procedureId).then((comments) => {
            $scope.procedure.hasComments = comments && comments.length > 0;
            $scope.procedure.comments = comments;
            $scope.$apply();
        }).catch((err) => {
            toast.show(err && err.message ? err.message : DEFAULT_ERROR_MESSAGE, 'error', false, 7500);
        });
    };

    //-------------------------------------------------------------------------- 
    $scope.getAssistances = (procedureId = null) => {
        procedureService.getAssistances(procedureId).then((assistances) => {
            $scope.procedure.assistances = assistances;
            $scope.$apply();
        }).catch((err) => {
            toast.show(err && err.message ? err.message : DEFAULT_ERROR_MESSAGE, 'error', false, 7500);
        });
    };
    $scope.callPhoneNumber = (phoneNumber) => {
        if (verifyProcedureHandle()) {
            if (phoneNumber.substr(0, 1) === '0') {
                phoneNumber = phoneNumber.substr(1);
            }
            window.location = 'web+aircall://+33' + phoneNumber;
        }
    };
    $scope.showSMSPopup = (procedureId, phoneNum = null, defaultMsg = null) => {
        if (verifyProcedureHandle()) {
            $mdDialog.show({
                templateUrl: TEMPLATES_DIR + 'send.sms.html',
                parent: angular.element(document.body),
                fullscreen: true,
                locals: {
                    procedureId: procedureId,
                    clientPhone: $scope.client.phone,
                    personPhone: (($scope.defaultPerson || $scope.defaultPerson.Address || {}).phone || null),
                    phoneNum: phoneNum,
                    defaultMessage: defaultMsg
                },
                controller: ($scope, $mdDialog, toast, procedureId, clientPhone, personPhone, phoneNum, defaultMessage) => {
                    $scope.procedureId = procedureId;
                    $scope.phoneNum = phoneNum;
                    $scope.selectedMessage = null;
                    $scope.smsCounter = null;
                    $scope.sms = {
                        phone: null,
                        message: defaultMessage || null
                    };
                    $scope.phones = [];
                    $scope.defaultMessages = [
                        "Votre dossier est incomplet." + "\n" + "Veuillez reprendre votre " +
                                "démarche sur notre application mobile Réflexe Carte Grise " +
                                "ou sur l'une de nos bornes et la finaliser.",
                        "Vous avez apparemment entamé une démarche sur le site de l'ANTS ce " +
                                "qui nous bloque pour le traitement de votre demande, " +
                                "veuillez alors annuler la démarche ANTS.",
                        "Vous pouvez dès à présent reprendre votre démarche sur notre " +
                                "application mobile Réflexe Carte Grise ou sur l'une de " +
                                "nos bornes et la finaliser.",
                        "Carte grise non signée par le vendeur." + "\n" + "Veuillez reprendre votre " +
                                "démarche sur notre appli Réflexe Carte Grise ou sur l'une de " +
                                "nos bornes et la finaliser.",
                        "Vous pouvez nous joindre par e-mail sur assistance@reflexecartegrise.fr",
                        "Vous pouvez télécharger notre appli sur : https://reflexecartegrise.fr/appli.php"
                    ];
                    let phones = [];
                    if (phoneNum !== null) {
                        let name = phoneNum;
                        if (phoneNum === clientPhone) {
                            name = 'N° tél du client ( ' + clientPhone + ' )';
                        } else if (personPhone !== null && phoneNum === personPhone) {
                            name = "N° tél de l'acquéreur / titulaire  ( " + personPhone + ' )';
                        }
                        phones.push(phoneNum);
                        $scope.phones = [
                            {
                                number: phoneNum,
                                name: name
                            }
                        ];
                    }
                    if (personPhone !== null && clientPhone !== personPhone && phones.indexOf(personPhone) < 0) {
                        $scope.phones.push({
                            number: personPhone,
                            name: "N° tél de l'acquéreur / titulaire ( " + personPhone + ' )'
                        });
                    }
                    if (phones.indexOf(clientPhone) < 0) {
                        $scope.phones.push({
                            number: clientPhone,
                            name: 'N° tél du client ( ' + clientPhone + ' )'
                        });
                    }
                    $scope.onMessageChanged = (fromList = false) => {
                        if (fromList === true) {
                            $scope.sms.message = $scope.selectedMessage;
                            $scope.selectedMessage = null;
                        }
                        if ($scope.sms.message !== '') {
                            $scope.smsCounter = smsCounter.count($scope.sms.message);
                        }//
                    };
                    $scope.closeDialog = () => {
                        $mdDialog.hide();
                    };
                    $scope.run = () => {
                        procedureService.sendSMS(procedureId, $scope.sms).then((result) => {
                            $mdDialog.hide();
                            if (result) {
                                toast.show(result.message, 'success');
                            }
                        }).catch((err) => {
                            console.log(err);
                            toast.show(err && err.message ? err.message : DEFAULT_ERROR_MESSAGE, 'error');
                        });
                    };
                }
            }).then(() => {
                /* dialog closed */
            });
        }//
    };
    $scope.showSendTerminalMessagePopup = (procedureId) => {
        if (verifyProcedureHandle()) {
            $mdDialog.show({
                templateUrl: TEMPLATES_DIR + 'send.terminal.message.html',
                parent: angular.element(document.body),
                fullscreen: true,
                locals: {
                    procedureId: procedureId
                },
                controller: ($scope, $mdDialog, toast, procedureId) => {
                    $scope.procedureId = procedureId;
                    $scope.closeDialog = (confirm = false) => {
                        $mdDialog.hide();
                        if (confirm) {
                            procedureService.sendTerminalMessage($scope.procedureId, {
                                message: $scope.message
                            }).then(() => {
                                toast.show('Le message a bien été envoyé.', 'success');
                                $route.reload();
                            }).catch((err) => {
                                toast.show(err && err.message ? err.message : DEFAULT_ERROR_MESSAGE, 'error');
                            });
                        }//
                    };
                }
            }).then(() => {
            }, () => {
            });
        }
    };

    $scope.showQuickProcedureValidatePopup = (procedureId) => {
        if (verifyProcedureHandle(true)) {
            $mdDialog.show({
                templateUrl: TEMPLATES_DIR + 'quick.validate.procedure.html',
                parent: angular.element(document.body),
                fullscreen: true,
                locals: {
                    procedureId: procedureId
                },
                controller: ($scope, $mdDialog, toast, procedureId) => {
                    let now = new Date(), minDate = new Date();
                    now.setMilliseconds(0);
                    minDate.setMonth(now.getMonth - 36);

                    $scope.validatedAt = null;
                    $scope.maxDate = now;
                    $scope.minDate = minDate;
                    $scope.procedureId = procedureId;

                    $scope.closeDialog = (confirm = false) => {
                        if (confirm) {
                            let data = {
                                validatedAt: $scope.validatedAt
                            };
                            procedureService.quickValidate(procedureId, data).then(() => {
                                $mdDialog.hide();
                                toast.show('La procédure a bien été validée.', 'success');
                                $location.search({});
                                $route.reload();
                            }).catch((err) => {
                                toast.show(err && err.message ? err.message : DEFAULT_ERROR_MESSAGE, 'error');
                            });
                        } else {
                            $mdDialog.hide();
                    }
                    };
                }
            });
        }
    };

    $scope.verifyTaxes = function () {
        if (verifyProcedureHandle()) {
            const data = getProcedureTaxesCalcData();
            if (data) {
                $rootScope.$broadcast('open-taxes-calc', data);
            }
        }
    };

//----------------------------------------------------------------------- ww ---

//------------------------------------------------------------------------------

    function isMobilePhoneNumber(number = '') {
        return /^(0)(6|7)(\d{2}){4}$/.test(number);
    }

    function setDefaultDataWarning() {
        let vehicleFormulaNum = ($scope.vehicle && $scope.vehicle.formulaNum ? $scope.vehicle.formulaNum : '').toLowerCase();
        let sellerFirstname = ($scope.seller && $scope.seller.firstname ? $scope.seller.firstname : '').toLowerCase();
        let sellerLastname = ($scope.seller && $scope.seller.lastname ? $scope.seller.lastname : '').toLowerCase();
        $scope.defaultDataWarningMsg = 'Il faut vérifier ';
        let changed = false;
        if (sellerFirstname === 'prénom' || sellerLastname === 'nom') {
            if (sellerFirstname === 'prénom') {
                $scope.defaultDataWarningMsg += 'le prénom';
                changed = true;
            }
            if (sellerLastname === 'nom') {
                $scope.defaultDataWarningMsg += (changed ? ' et ' : '') + 'le nom';
                changed = true;
            }
            $scope.defaultDataWarningMsg += ' du vendeur';
        }
        if (vehicleFormulaNum === '1234aa12345') {
            changed = true;
            $scope.defaultDataWarningMsg += (changed ? ' et ' : '') + 'le N° de formule de véhicule';
        }
        if (!changed) {
            $scope.defaultDataWarningMsg = null;
        }
    }

    function getProcedure(id) {
        procedureService.get({
            id: id,
            type: $scope._type
        }).then((data) => {

            $scope.procedure = Object.assign({}, data);
            $scope.procedureType = $scope.procedure.type || null;
            $scope.procedure.history = [];
            $scope.origin = procedureTools.getProcedureOrigin($scope.procedure);
            $scope.procedure.origin = procedureTools.getOrigin($scope.procedure);

            $scope.client = Object.assign({}, data.Client);
            $scope.client.hasMobilePhoneNumber = isMobilePhoneNumber($scope.client.phone);

            $scope.procedure.showChatWidget = $scope.procedure.WebAppId === '40fc1446-f970-4c42-9f72-d87030213d38';
            //$scope.client.type === 'partenaire';

            $scope.item = Object.assign({payments: []}, data[$scope.PROCEDURE_PARAMS.instanceName]);

            let residenceDep = procedureTools.getDepartementByCodeDep($scope.item.codeDep) || null;
            if (residenceDep !== null) {
                $scope.item.residenceDepName = residenceDep.name;
                $scope.item.residenceDepLink = residenceDep.link;
            }

            if ($scope.isDUP === true) {
                $scope.item.motifLabel = procedureTools.getDuplicataMotifLabel($scope.item.motif);
                setEventDatePicker($scope.item.eventDate);
            }
            $scope.vehicle = Object.assign({}, $scope.item.Vehicle);
            $scope.vehicle.isVP = $scope.vehicle.type === 1;

            $scope.terminal = Object.assign({}, data.Terminal);
            $scope.isTerminalProcedure = $scope.terminal !== null && (typeof $scope.terminal !== 'undefined');
            $scope.isNewTerminal = $scope.isTerminalProcedure && $scope.terminal.isNew === true;

            $scope.procedure.isHandled = procedureTools.isHandled($scope.procedure);
            $scope.procedure.isHandledByUser = procedureTools.isHandled($scope.procedure, true);
            $scope.procedure.canBeHandledByUser = procedureTools.isHandled($scope.procedure, true, true);
            $scope.procedure.pending = $scope.procedure.status === 'pending';

            $scope.procedure.isTMSReady = $scope.item.status === 'tms-ready';
            $scope.procedure.isSentToTMS = $scope.item.status === 'sent-to-tms';
            $scope.procedure.isRejected = $scope.item.status === 'rejected';
            $scope.procedure.isArchived = $scope.item.status === 'archived';
            $scope.procedure.isInProcess = $scope.item.status === 'processing';
            $scope.procedure.isPaid = $scope.procedure.paid === true && $scope.procedure.paidAt !== null;

            $scope.procedure.isDisabled = $scope.procedure.isRejected || $scope.procedure.isArchived;
            $scope.procedure.isEnabled = !$scope.procedure.isDisabled;
            $scope.procedure.hasRefund = $scope.procedure.refundedAt !== null;

            $scope.procedure.isReadyToBeValidated =
                    $scope.procedure.allDocumentsAccepted === true &&
                    $scope.procedure.allDocumentsUploaded === true &&
                    $scope.procedure.isTMSReady === false &&
                    $scope.procedure.isSentToTMS === false;

            setProcedureType($scope.procedure);

            if ($scope.procedure.isCIM === true) {

                let dep = gData.getDepartement($scope.item.codeDep);
                $scope.showRegionTaxeWarning = dep && dep.hasUpdatedTaxe === true;

                $scope.showVehicleTaxablePowerWarning = $scope.vehicle.isVP && $scope.vehicle.taxableHorsepower < 3;
                $scope.showY1TaxeWarning = $scope.vehicle.isVP && $scope.item.taxY1 < 50;
            }

            if ($scope.procedure.isANTS === true) {
                $scope.antsProcedure = $scope.procedure.ANTSProcedure;
                if ($scope.antsProcedure !== null) {
                    $scope.antsProcedure.isReadyToBeSent = $scope.antsProcedure.sentAt === null;
                    $scope.antsProcedure.isSent = $scope.antsProcedure.sentAt !== null;
                    $scope.antsProcedure.isValidated = $scope.antsProcedure.isSent && $scope.antsProcedure.validatedAt !== null;
                    $scope.antsProcedure.isReadyToBeValidated = $scope.antsProcedure.isSent && !$scope.antsProcedure.isValidated;
                }
            }

            procedureTools.initProcedurePeople($scope);
            procedureTools.initVehicle($scope);
            procedureTools.initDocuments($scope);

            $scope.procedure.tmsErrors = JSON.parse(data.tmsErrors) || null;
            if ($scope.procedure.tmsErrors && Array.isArray($scope.procedure.tmsErrors)) {
                $scope.procedure.tmsErrors = $scope.procedure.tmsErrors.reverse();
            }
            $scope.hasTMSErrors = !$scope.procedure.isSentToTMS && $scope.procedure.tmsErrors !== null;

            $scope.procedure.hasComments = false;
            $scope.setSaleDateTimeEdit($scope.item.saleDate === null && $scope.item.saleTime === null);

            if ($routeParams.tab && $routeParams.doc) {
                let Documentsearched = $routeParams.doc;
                for (let i = 0; i < $scope.procedure.Documents.length; i++) {
                    let Document = $scope.procedure.Documents[i];
                    if ((Document._id === Documentsearched) && $routeParams.doc)
                        $scope.Document = Document;
                }
                setTimeout(() => {
                    $scope.checkDocument($scope.Document);
                }, 150);
            }

            setDefaultDataWarning();

            if ($scope.procedure.pending) {
                if ($scope.procedure.isCIM === true && moment($scope.procedure.createdAt).isBefore('2024-01-01')) {
                    $scope.isOldProcedure = true;
                }
                $scope.getRefund($scope.procedureId);
            }

            $scope.$apply();

            if ($scope.currentTab + '' === '2') {
                setTimeout(() => {
                    $rootScope.gotoTab($scope, 2, 850);
                }, 150);
            }

            setTimeout(() => {
                $scope.$apply();
            }, 0);

            setTimeout(() => {
                if (verifyProcedureHandle(false)) {
                    $scope.getComments(id);
                }
            }, 350);

        });
    }

    function getProcedureTaxesCalcData() {
        if (!$scope.procedure.isCIM) {
            return null;
        }
        return {
            procedureId: $scope.procedure._id,
            data: {
                typeVehicule: $scope.vehicle.type,
                energie: $scope.vehicle.fuelType,
                dateMiseEnCirculation: $scope.vehicle.firstRegistrationDate,
                puissanceAdministrative: $scope.vehicle.taxableHorsepower,
                departement: $scope.item.codeDep,
                taxes: {
                    y1: $scope.item.taxY1,
                    y2: $scope.item.taxY2,
                    y3: $scope.item.taxY3,
                    y4: $scope.item.taxY4,
                    y5: $scope.item.taxY5,
                    y6: $scope.item.taxY6
                }
            }
        };
    }

    function setProcedureType(procedure) {
        procedure.isCIM = procedure.type === config.app.procedureTypes.cim;
        procedure.isCIWW = procedure.type === config.app.procedureTypes.ciww;
        procedure.isDUP = procedure.type === config.app.procedureTypes.dup;
        procedure.isCAD = procedure.type === config.app.procedureTypes.cad;
        procedure.isVENL = procedure.type === config.app.procedureTypes.venl;
        procedure.isPC = procedure.type === config.app.procedureTypes.pc;
        procedure.isPIM = procedure.type === config.app.procedureTypes.pim;
        procedure.isDA = procedure.type === config.app.procedureTypes.da;
        procedure.isDC = procedure.type === config.app.procedureTypes.dc;
    }

    function getProcedures() {

        let timestamp = new Date().getTime();

        if (lastProceduresReqTime !== null) {
            console.error('lastProceduresReqTime : ' + (timestamp - lastProceduresReqTime));
        }

        if (!(lastProceduresReqTime === null || (timestamp > lastProceduresReqTime + 5000))) {
            return;
        }

        lastProceduresReqTime = timestamp;

        $scope.procedures = [];

        let params = {
            type: $scope._type,
            ants: $scope.isANTS === true ? 1 : 0,
            pendingOnly: $scope.pagination.pendingOnlyProcedures === true ? 1 : 0
        };
        if ($scope.pagination.filters) {
            let filters = $scope.pagination.filters;
            for (let i in filters) {
                if (i === 'date') {
                    params[i] = moment(filters[i]).format(config.date.defaultFormat);
                } else if (i === 'DiscountCode') {
                    if (filters.DiscountCode !== null) {
                        params.discountCode = filters.DiscountCode._id;
                    }
                } else {
                    if (filters[i] !== '') {
                        params[i] = filters[i];
                        // force ants mode (tab)
                        if (i === 'antsProcedureNum' && typeof params[i] === 'string') {
                            $scope.currentProceduresTab = 1;
                            $scope.hasANTSProcedures = true;
                            $scope.isANTS = true;
                            params.ants = true;
                        }
                    }
                }
            }
        }

        procedureService.get(params, $scope.pagination.currentPage, $scope.pagination.itemsPerPage).then((data) => {
            $scope.pagination.params = params;
            $scope.proceduresCount = data.count;
            $scope.procedures = data.items;
            for (let i = 0; i < $scope.procedures.length; i++) {
                let procedure = $scope.procedures[i];
                procedure.origin = procedureTools.getOrigin(procedure);
                procedure.isHandled = procedureTools.isHandled(procedure);
                procedure.isHandledByUser = procedure.UserId === $rootScope.currentUser._id;

                let item = procedure[$scope.PROCEDURE_PARAMS.instanceName];
                procedure.item = item;
                procedure.isSentToTMS = procedure.item.status === 'sent-to-tms';
                procedure.completed = procedure.status === 'completed';
                procedure.pending = procedure.status === 'pending';
                procedure.isRecentlyCreated = false;
                procedure.isRecentlyUpdated = false;
                procedure.isReadyForProcessing =
                        procedure.pending &&
                        !procedure.isSentToTMS &&
                        procedure.paid === true &&
                        procedure.signed === true &&
                        procedure.documentsUploaded === true &&
                        procedure.waitingForDocuments === false;

                procedure.showWaitingForDocuments = procedure.paid === true &&
                        (procedure.waitingForDocuments === true || procedure.documentsUploaded === false);

                procedure.waitingForDocuments01 = procedure.showWaitingForDocuments === true &&
                        procedure.documentsUploaded === false;

                procedure.waitingForDocuments02 = procedure.showWaitingForDocuments === true &&
                        procedure.documentsUploaded === true && procedure.waitingForDocuments === true;

                if (procedure.completed === true) {
                    procedure.showWaitingForDocuments = false;
                    procedure.showWaitingForDocuments01 = false;
                    procedure.showWaitingForDocuments02 = false;
                }

                procedure.clientName = (procedure.Client.title + ' ' +
                        procedure.Client.firstname + ' ' + procedure.Client.lastname).toLowerCase();

                procedure.link = '/procedures/' + procedure.type + '/' + procedure._id;

                if (procedure.isReadyForProcessing) {
                    let createdAt = moment(procedure.createdAt);
                    let updatedAt = moment(procedure.updatedAt);
                    let _3Days = moment().subtract(1, 'days');
                    let hasAtLeast3Days = createdAt.isSameOrBefore(_3Days);
                    let updatedRecently = updatedAt.isSameOrAfter(_3Days);
                    if (hasAtLeast3Days && updatedRecently &&
                            procedure.signed === true &&
                            procedure.documentsUploaded === true &&
                            procedure.waitingForDocuments === false) {
                        procedure.isRecentlyUpdated = true;
                    } else {
                        procedure.isRecentlyCreated = true;
                    }
                }

                procedure.ANTSProcedureNum = null;
                if (procedure.isANTS === true && procedure.ANTSProcedure !== null &&
                        typeof procedure.ANTSProcedure.externalProcedureNum === 'string') {
                    procedure.ANTSProcedureNum = procedure.ANTSProcedure.externalProcedureNum;
                }
                procedure.class = '';
                if (procedure.refunded === true || procedure.refundedAt !== null) {
                    procedure.showWaitingForDocuments = false;
                    procedure.class = 'refunded';
                } else if (procedure.status === 'completed') {
                    procedure.class = 'completed';
                } else if (procedure.isRecentlyUpdated === true) {
                    procedure.class = 'updated';
                } else if (procedure.isRecentlyCreated === true) {
                    procedure.class = 'new_created';
                } else if (!procedure.paid) {
                    procedure.class = 'not_paid';
                }
            }
            $scope.pagination.totalItems = data.count;
            $timeout(() => $scope.$apply());

        });
    }

    function getNeverHandledProcedure() {
        procedureService.getNeverHandledProcedures().then((data) => {
            $scope.procedures = data;
            $scope.procedures.forEach((procedure) => {
                procedure.origin = procedureTools.getOrigin(procedure);
                if (procedure.origin.label === 'Borne inconnue') {
                    procedure.origin.label = 'Borne';
                }
                procedure.link = '/procedures/' + procedure.type + '/' + procedure._id;
                procedure.cssClass = getProcedureCSSClass(procedure.type) || '';
            });
            $timeout(() => $scope.$apply());
        });
    }

    $scope.gotoTMSTab = function () {
        const tab = $scope.$mdTabsCtrl.tabs.find((tab) => tab.label.toLowerCase().includes('tms'));
        if (tab) {
            $scope.currentTab = tab.index;
        }
    };

    $scope.gotoTaxesTab = function () {
        const tab = $scope.$mdTabsCtrl.tabs.find((tab) => tab.label.toLowerCase().includes('taxes'));
        if (tab) {
            $scope.currentTab = tab.index;
        }
    };

    $scope.gotoCommentsTab = function () {
        const tab = $scope.$mdTabsCtrl.tabs.find((tab) => tab.label.toLowerCase().includes('comment'));
        if (tab) {
            $scope.currentTab = tab.index;
        }
    };

//--------------------------------------------------------------------- chat ---

    $scope.gotoChatTab = function () {
        const tab = $scope.$mdTabsCtrl.tabs.find((tab) => tab.label.toLowerCase().includes('message'));
        if (tab) {
            $scope.currentTab = tab.index;
        }
    };

    $scope.newChatMessage = {
        message: ''
    };

    $scope.formatText = function (message, format) {
        switch (format) {
            case 'b':
                return applyFormatting(message, '**');
            case 'u':
                return applyFormatting(message, '__');

        }
    };

    function applyFormatting(message, tag) {
        let messageInput = document.getElementById('message-input');
        let selectionStart = messageInput.selectionStart;
        let selectionEnd = messageInput.selectionEnd;
        $scope.newChatMessage.message = message.substring(0, selectionStart) + tag +
                message.substring(selectionStart, selectionEnd) + tag +
                message.substring(selectionEnd);
    }

    $scope.postChatMessage = () => {
        procedureService.postChatMessage($scope.procedureId, {
            message: $scope.newChatMessage.message
        }).then(() => {
            $scope.newChatMessage.message = '';
            toast.show('Le message a bien été envoyé.', 'success');
            $scope.getChatMessages();
        }).catch((err) => {
            toast.show(err && err.message ? err.message : DEFAULT_ERROR_MESSAGE, 'error');
        });
    };

    $scope.getChatMessages = () => {
        if ($scope.procedure.isHandledByUser) {
            procedureService.getChatMessages($scope.procedureId).then((data) => {
                $scope.procedure.chatMessages = data;
                $scope.procedure.chatMessages.forEach((chatMessage) => {
                    chatMessage.message = chatMessage.message
                            .replace(/\n/g, '<br>')
                            .replace(/__(.*?)__/g, '<u>$1</u>')
                            .replace(/\*\*(.*?)\*\*/g, '<b>$1</b>')
                            .replace(/(https?:\/\/[^\s]+)/g, '<a href="$1" target="_blank">$1</a>');
                });
                // update all unread messages
                if ($scope.procedure.chatMessages.some((item) => item.senderType === 'client' && item.read === false)) {
                    procedureService.setChatMessagesAsRead($scope.procedureId);
                }
                $timeout(() => $scope.$apply());
                $timeout(() => {
                    const messagesContainer = document.querySelector('.messages-container');
                    if (messagesContainer) {
                        messagesContainer.scrollTo({
                            top: messagesContainer.scrollHeight,
                            behavior: 'smooth'
                        });
                    }
                }, 123);
            }).catch((err) => {
                toast.show(err && err.message ? err.message : DEFAULT_ERROR_MESSAGE, 'error');
            });
        }
    };

    function getProcedureCSSClass(procedureType) {
        for (const [key, value] of Object.entries(config.app.procedureTypes)) {
            if (procedureType === value) {
                return key;
            }
        }
        return null;
    }

//------------------------------------------------------------------------------

    if ($scope.isItem) {
        getProcedure($scope.procedureId);
    } else {
        if ($scope.isNeverHandledProcedures === true) {
            setProcedureEditPermissions();
            getNeverHandledProcedure();
        } else {
            getProcedures();
        }
    }

});